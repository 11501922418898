import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const ListaMunicipios = () => {
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    getMunicipios();
  }, []);

  const getMunicipios = async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/municipios`);
    setMunicipios(response.data);
  };

  const deleteMunicipio = async (municipioId) => {
    await axios.delete(`${baseURL.BASE_URL}/municipios/delete_municipio/${municipioId}`);
    getMunicipios();
  };

  return (
    <div>
      <h1 className="title">Municipios</h1>
      <h2 className="subtitle">Lista de Municipios</h2>
      <Link to="/municipios/add_municipio" className="button is-primary mb-2">Añadir Municipio</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Municipio</th>
            <th>Distancia Km</th>
            <th>Distancia Tiempo</th>
            <th>Categoria</th>
            <th>Accion</th>
          </tr>
        </thead>
        <tbody>
          {municipios.map((municipio, index) => (
            <tr key={municipio.uuid_municipio}>
              <td>{index + 1}</td>
              <td>{municipio.nombre_municipio}</td>
              <td>{municipio.distancia_km_municipio}</td>
              <td>{municipio.distancia_tiempo_municipio}</td>
              <td>{municipio.distancia_categoria_municipio}</td>
              <td>
                <Link to={`/municipios/update_municipio/${municipio.uuid_municipio}`} className="button is-small is-info">Editar</Link>
                <button onClick={() => deleteMunicipio(municipio.uuid_municipio)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaMunicipios;
