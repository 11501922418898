import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const ListaCursos = () => {
  const [cursos, setCursos] = useState([]);

  useEffect(() => {
    getCursos();
  }, []);

  const getCursos = async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/cursos`);
    setCursos(response.data);
  };

  const deleteCursos = async (cursoId) => {
    await axios.delete(`${baseURL.BASE_URL}/cursos/delete_curso/${cursoId}`);
    getCursos();
  };

  return (
    <div>
      <h1 className="title">Cursos</h1>
      <h2 className="subtitle">Lista de Cursos</h2>
      <Link to="/cursos/add_curso" className="button is-primary mb-2">Añadir Curso</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Curso</th>
            <th>Accion</th>

          </tr>
        </thead>
        <tbody>
          {cursos.map((curso, index) => (
            <tr key={curso.uuid_curso}>
              <td>{index + 1}</td>
              <td>{curso.nombre_curso}</td>
              <td>
                <button onClick={() => deleteCursos(curso.uuid_curso)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaCursos;
