import React, { useState, useEffect } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import "../../styles/AddUsuarioStyle.css";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormEditUsuario = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [formData, setFormData] = useState({
        apellido_paterno_usuario: '',
        apellido_materno_usuario: '',
        nombre_usuario: '',
        correo_usuario: '',
        fecha_nacimiento_usuario: '',
        edad_usuario: '',
        id_genero: '',
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    const generatePassword = () => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
        let newPassword = "";
        for (let i = 0; i < 12; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            newPassword += charset[randomIndex];
        }
        setPassword(newPassword);
    };

    useEffect(() => {
        const getUsuarioById = async () => {
            try {
                const response = await axios.get(`${baseURL.BASE_URL}/usuarios/${id}`);
                setFormData({
                    apellido_paterno_usuario: response.data.apellido_paterno_usuario,
                    apellido_materno_usuario: response.data.apellido_materno_usuario,
                    nombre_usuario: response.data.nombre_usuario,
                    correo_usuario: response.data.correo_usuario,
                    fecha_nacimiento_usuario: response.data.fecha_nacimiento_usuario,
                    edad_usuario: response.data.edad_usuario,
                    id_genero: response.data.id_genero,
                });
                if (response.data.imagen_usuario) {
                    setImagePreview(`${baseURL.BASE_URL}/uploads/images/${response.data.imagen_usuario}`);
                }
            } catch (error) {
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            }
        };
        getUsuarioById();
    }, [id]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({ ...formData, imagen: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreview(null);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const updateUsuario = async (e) => {
        e.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            if (key === 'imagen' && formData[key] === null) {
                continue; // Skip adding 'imagen' if it is null
            }
            data.append(key, formData[key]);
        }
        data.append('password_usuario', password); // Añade la contraseña generada o vacía

        try {
            await axios.patch(`${baseURL.BASE_URL}/usuarios/update_usuario/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate("/usuarios");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Usuarios</h1>
            <h2 className="subtitle">Editar usuario</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={updateUsuario}>
                            <p className='has-text-centered'>{msg}</p>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Apellido Paterno :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="apellido_paterno_usuario" value={formData.apellido_paterno_usuario} onChange={handleInputChange} placeholder="Ingrese apellido paterno..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Nombre :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="nombre_usuario" value={formData.nombre_usuario} onChange={handleInputChange} placeholder="Ingrese el nombre..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Correo :</label>
                                        <div className="control">
                                            <input type="email" className="input" name="correo_usuario" value={formData.correo_usuario} onChange={handleInputChange} placeholder="Ingrese el correo..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Contraseña:</label>
                                        <div className="control has-icons-right">
                                            <input
                                                type={passwordVisible ? "text" : "password"}
                                                className="input"
                                                name="password_usuario"
                                                placeholder="Contraseña"
                                                value={password}
                                                readOnly
                                            />
                                            <span className="icon is-small is-right password-icon" onClick={togglePasswordVisibility}>
                                                {passwordVisible ? <IoEyeOff /> : <IoEye />}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Edad :</label>
                                        <div className="control">
                                            <input type="number" className="input" name="edad_usuario" value={formData.edad_usuario} onChange={handleInputChange} placeholder="Ingrese la edad..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Apellido Materno :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="apellido_materno_usuario" value={formData.apellido_materno_usuario} onChange={handleInputChange} placeholder="Ingrese apellido materno..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Fecha nacimiento :</label>
                                        <div className="control">
                                            <input type="date" className="input" name="fecha_nacimiento_usuario" value={formData.fecha_nacimiento_usuario} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Genero :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_genero" value={formData.id_genero} onChange={handleInputChange}>
                                                    <option value="1">Masculino</option>
                                                    <option value="2">Femenino</option>
                                                    <option value="3">No Binario</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Generar Contraseña:</label>
                                        <div className="control">
                                            <button type="button" className="button is-primary is-fullwidth" onClick={generatePassword}>
                                                Reestablecer Contraseña
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Foto :</label>
                                        <div className="control">
                                            <div className="image-upload">
                                                {imagePreview ? (
                                                    <figure className="image is-128x128 preview-box">
                                                        <img src={imagePreview} alt="Previsualización de la imagen" />
                                                    </figure>
                                                ) : (
                                                    <div className="box preview-box">
                                                        <p>No hay imagen seleccionada</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="file is-primary mt-2">
                                                <label className="file-label">
                                                    <input className="file-input" type="file" accept="image/*" onChange={handleImageChange} />
                                                    <span className="file-cta">
                                                        <span className="file-label">Seleccionar archivo</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Actualizar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormEditUsuario;
