import {BrowserRouter,Routes,Route} from "react-router-dom"
import Dashboard from "./pages/Dashboard";
import LogIn from "./components/LogIn";
import Usuarios from "./pages/Usuario/Usuarios";
import Municipios from "./pages/Municipio/Municipios";
import AddUsuario from "./pages/Usuario/AddUsuario";
import EditUsuario from "./pages/Usuario/EditUsuario";
import AddMunicipio from "./pages/Municipio/AddMunicipio";
import EditMunicipio from "./pages/Municipio/EditMunicipio";
import Roles from "./pages/Roles/Roles";
import AddRol from "./pages/Roles/AddRol";
import RolesAsignacion from "./pages/Roles/RolesAsignacion";
import Tutores from "./pages/Tutor/Tutores";
import AddTutor from "./pages/Tutor/AddTutor";
import EditRol from "./pages/Roles/EditRol";
import EditTutor from "./pages/Tutor/EditTutor";
import AsignacionTutores from "./pages/Tutor/AsignacionTutores";
import AddAsignacionTutor from "./pages/Tutor/AddAsignacionTutor";
import AddRolUsuario from "./pages/Roles/AddRolesUsuarios";
import UnidadesEducativas from "./pages/UnidadesEducativas/UnidadesEducativas";
import AddUnidadEducativa from "./pages/UnidadesEducativas/AddUnidadEducativa";
import EditUnidadEducativa from "./pages/UnidadesEducativas/EditUnidadEducativa";
import Cursos from "./pages/Cursos/Cursos";
import Niveles from "./pages/Cursos/Niveles";
import CursosConformados from "./pages/Cursos/CursosConformados";
import AddCurso from "./pages/Cursos/AddCurso";
import AddNivel from "./pages/Cursos/AddNivel";
import AddCursoConformado from "./pages/Cursos/AddCursoConformado";
import Equipos from "./pages/Equipos/Equipos";
import AddEquipo from "./pages/Equipos/AddEquipo";
import CategoriasCompetencia from "./pages/CategoriasCompetencia/CategoriasCompetencia";
import AddCategoriaCompetencia from "./pages/CategoriasCompetencia/AddCategoriaCompetencia";
import ListaEstudiantesF from "./pages/Estudiantes/Estudiantes";
import AddEstudiante from "./pages/Estudiantes/AddEstudiante";
import AddRetoCalificador from "./pages/RetoCalificador/AddRetoCalificador";
import EntregasRetosCalificadores from "./pages/EntregasRetosCalificadores/EntregasRetosCalificadores";
import "./styles/App.css"
import RetosCalificadores from "./pages/RetoCalificador/RetosCalificadores"
import AddEntregasRetosCalificadores from "./pages/RetoCalificador/AddRetoCalificador"
import EntregasRetosCalificadoresGeneral from "./pages/EntregasRetosCalificadores/EntregaRetosCalificadoresGeneral";
function App() {
  return (
    <div className="contenedor" >
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<LogIn/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/usuarios" element={<Usuarios/>}/>
        <Route path="/usuarios/add_usuario" element={<AddUsuario/>}/>
        <Route path="/usuarios/update_usuario/:id" element={<EditUsuario/>}/>
        <Route path="/municipios" element={<Municipios/>}/>
        <Route path="/municipios/add_municipio" element={<AddMunicipio/>}/>
        <Route path="/municipios/update_municipio/:id" element={<EditMunicipio/>}/>
        <Route path="/roles" element={<Roles/>}/>
        <Route path="/roles/add_rol" element={<AddRol/>}/>
        <Route path="/roles/update_rol/:id" element={<EditRol/>}/>
        <Route path="/rolesUsuarios" element={<RolesAsignacion/>}/>
        <Route path="/rolesUsuarios/add_rolUsuario" element={<AddRolUsuario/>}/>
        <Route path="/tutores" element={<Tutores/>}/>
        <Route path="/tutores/add_tutor" element={<AddTutor/>}/>
        <Route path="/tutores/update_tutor/:id" element={<EditTutor/>}/>
        <Route path="/tutoresAsignacion" element={<AsignacionTutores/>}/>
        <Route path="/tutoresAsignacion/add_tutorAsignacion" element={<AddAsignacionTutor/>}/>
        <Route path="/unidadesEducativas" element={<UnidadesEducativas/>}/>
        <Route path="/unidadesEducativas/add_unidadEducativa" element={<AddUnidadEducativa/>}/>
        <Route path="/unidadesEducativas/update_unidadEducativa/:id" element={<EditUnidadEducativa/>}/>
        <Route path="/cursos" element={<Cursos/>}/>
        <Route path="/cursos/add_curso" element={<AddCurso/>}/>
        <Route path="/niveles" element={<Niveles/>}/>
        <Route path="/niveles/add_nivel" element={<AddNivel/>}/>

        <Route path="/cursosConformados" element={<CursosConformados/>}/>
        <Route path="/cursosConformados/add_cursoConformado" element={<AddCursoConformado/>}/>
        <Route path="/equipos" element={<Equipos/>}/>
        <Route path="/equipos/add_equipo" element={<AddEquipo/>}/>

        <Route path="/categoriasCompetencia" element={<CategoriasCompetencia/>}/>
        <Route path="/categoriasCompetencia/add_categoria_competencia" element={<AddCategoriaCompetencia/>}/>
        <Route path="/estudiantes" element={<ListaEstudiantesF/>}/>
        <Route path="/estudiantes/add_estudiante" element={<AddEstudiante/>}/>

        <Route path="/retosCalificadores" element={<RetosCalificadores/>}/>
        <Route path="/retosCalificadores/add_retoCalificador" element={<AddRetoCalificador/>}/>
        <Route path="/entregasRetosCalificadores" element={<EntregasRetosCalificadores/>}/>
        <Route path="/entregasRetosCalificadores/add_entregaRetoCalificador" element={<AddEntregasRetosCalificadores/>}/>
        <Route path="/entregasRetosCalificadores/tablaGeneral" element={<EntregasRetosCalificadoresGeneral/>}/>


      </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
