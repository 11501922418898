import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IoSearch, IoFilter } from 'react-icons/io5';
import '../../styles/ListaTutoresAsignaciones.css'; // Importar el archivo CSS
import baseURL from "../../features/baseURL"

const ListaCategoriasCompetencia= () => {
  const [categoriasCompetencia,setCategoriasCompetencia] = useState([]);
;


  useEffect(() => {
    getCategoriasCompetencias();
  }, []);

  const getCategoriasCompetencias = async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/categoriasCompetencia`);
    setCategoriasCompetencia(response.data);
  };

  const deleteCategoriaCompetencia = async (categoriaCompetenciaId) => {
    console.log(categoriaCompetenciaId)
    await axios.delete(`${baseURL.BASE_URL}/categoriasCompetencia/delete_categoria_competencia/${categoriaCompetenciaId}`);
    getCategoriasCompetencias();
  };

  return (
    <div>
      <h1 className="title">Asignacion de Categorias de Competencia</h1>
      <h2 className="subtitle">Lista de Categorias de Competencia</h2>
      <Link to="/categoriasCompetencia/add_categoria_competencia" className="button is-primary mb-2">Añadir Categoria</Link>
      
      
      
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Categoria</th>
            <th>Accion</th>
          </tr>
        </thead>
        <tbody>
          {categoriasCompetencia.map((categoriaCompetencia, index) => (
            <tr key={categoriaCompetencia.uuid_categoria_competencia}>
              <td>{index + 1}</td>
              <td>{categoriaCompetencia.nombre_categoria}</td>

              <td>
                <button onClick={() => deleteCategoriaCompetencia(categoriaCompetencia.uuid_categoria_competencia)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaCategoriasCompetencia;
