import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddCursoConformado = () => {
    const navigate = useNavigate();
    const [id_curso,setCurso]=useState("")
    const [id_nivel,setNivel]=useState("")
    const [id_categoriaCompetencia,setCategoriaCompetencia]=useState("")
    const [cursos,setCursos]=useState([])
    const [niveles,setNiveles]=useState([])
    const [categoriasCompetencia,setCategoriasCompetencia]=useState([])
    const [msg, setMsg] = useState("");
  

    useEffect(() => {
       getCursos();
       getNiveles();
       getCategoriasCompetencia();
    }, []);

    const getCursos = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/cursos`);
        setCursos(response.data);
    };

    const getNiveles = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/niveles`);
        setNiveles(response.data);
    };
    const getCategoriasCompetencia=async()=>{
        const response=await axios.get(`${baseURL.BASE_URL}/categoriasCompetencia`);
        setCategoriasCompetencia(response.data);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(id_curso,id_nivel)
        try {
            await axios.post(`${baseURL.BASE_URL}/cursosConformados/add_curso_conformado`, {
                id_curso: id_curso,
                id_nivel:id_nivel,
                id_categoriaCompetencia:id_categoriaCompetencia
            });

            navigate("/cursosConformados");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Cursos Conformados</h1>
            <h2 className="subtitle">Añadir Conformacion de curso</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        {msg && <p className="error-message">{msg}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    
                                   

                                    <div className="field">
                                        <label className="label">Curso :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_curso"
                                                    value={id_curso}
                                                    onChange={(e) => setCurso(e.target.value)}
                                                    
                                                >
                                                    {cursos.map(curso => (
                                                        <option key={curso.id} value={curso.id}>
                                                            {curso.nombre_curso}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Categoría Competencia :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_categoriaCompetencia"
                                                    value={id_categoriaCompetencia}
                                                    onChange={(e) => setCategoriaCompetencia(e.target.value)}
                                                    
                                                >
                                                    {categoriasCompetencia.map(categoriaCompetencia => (
                                                        <option key={categoriaCompetencia.id} value={categoriaCompetencia.id}>
                                                            {categoriaCompetencia.nombre_categoria}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nivel :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_nivel"
                                                    value={id_nivel}
                                                    onChange={(e) => setNivel(e.target.value)}
                                                >
                                                    {niveles.map(nivel => (
                                                        <option key={nivel.id} value={nivel.id}>
                                                            {nivel.nombre_nivel}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddCursoConformado;
