import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddAsignacionTutor = () => {
    const navigate = useNavigate();
    const [tutores, setTutores] = useState([]);
    const [filteredTutores, setFilteredTutores] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [nombre_completo_tutor, setNombreTutor] = useState("");
    const [correo_tutor, setCorreoTutor] = useState("");
    const [telefono_tutor, setTelefonoTutor] = useState("");
    const [id_olimpiada, setOlimpiada] = useState("");
    const [id_unidadEducativa, setUnidadEducativa] = useState("");
    const [id_materia, setMateriaTutor] = useState("");
    const [id_equipo, setEquipoTutor] = useState("");

    const [msg, setMsg] = useState("");
    const [unidadesEducativas, setUnidadesEducativas] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [olimpiadas, setOlimpiadas] = useState([]);
    const [equipos, setEquipos] = useState([]);

    useEffect(() => {
        getTutores();
        getUnidadesEducativas();
        getMaterias();
        getOlimpiadas();
    }, []);

    useEffect(() => {
        if (id_unidadEducativa) {
            getEquiposByUnidadEducativa(id_unidadEducativa);
        } else {
            setEquipos([]);
        }
    }, [id_unidadEducativa]);

    const getTutores = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/tutores`);
        setTutores(response.data);
    };

    const getUnidadesEducativas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/unidadesEducativas`);
        setUnidadesEducativas(response.data);
    };

    const getMaterias = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/materias`);
        setMaterias(response.data);
    };

    const getOlimpiadas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/olimpiadas`);
        setOlimpiadas(response.data);
    };

    const getEquiposByUnidadEducativa = async (unidadEducativaId) => {
        const response = await axios.get(`${baseURL.BASE_URL}/equipos/unidadEducativa/${unidadEducativaId}`);
        setEquipos(response.data);
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setNombreTutor(query);
        if (query.length > 0) {
            const filtered = tutores.filter(tutor =>
                tutor.nombre_completo && typeof tutor.nombre_completo === 'string' &&
                tutor.nombre_completo.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredTutores(filtered);
        } else {
            setFilteredTutores([]);
        }
    };


    const handleSelectTutor = (tutor) => {
        setSelectedTutor(tutor.id);
        setNombreTutor(tutor.nombre_completo);
        setCorreoTutor(tutor.correo_tutor);
        setTelefonoTutor(tutor.telefono_tutor);
        setOlimpiada(tutor.olimpiada.id);
        setFilteredTutores([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${baseURL.BASE_URL}/tutoresAsignacion/add_tutorAsignacion`, {
                id_tutor: selectedTutor,
                id_materia,
                id_unidadEducativa,
                id_olimpiada,
                id_equipo
            });
            navigate("/tutoresAsignacion");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Tutores</h1>
            <h2 className="subtitle">Añadir Asignación de Tutor</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        {msg && <p className="error-message">{msg}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nombre Completo :</label>
                                        <div className="control">
                                            <input
                                                type="text"
                                                className="input"
                                                name="nombre_completo_tutor"
                                                value={nombre_completo_tutor}
                                                onChange={handleSearch}
                                                placeholder="Buscar tutor por nombre..."
                                            />
                                            {filteredTutores.length > 0 && (
                                                <div className="dropdown is-active">
                                                    <ul className="dropdown-menu">
                                                        {filteredTutores.map((tutor) => (
                                                            <li
                                                                key={tutor.id}
                                                                onClick={() => handleSelectTutor(tutor)}
                                                                className="dropdown-item"
                                                            >
                                                                {tutor.nombre_completo}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Correo :</label>
                                        <div className="control">
                                            <input
                                                type="email"
                                                className="input"
                                                name="correo_tutor"
                                                value={correo_tutor}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Telefono Tutor :</label>
                                        <div className="control">
                                            <input
                                                type="number"
                                                className="input"
                                                name="telefono_tutor"
                                                value={telefono_tutor}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Olimpiada :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_olimpiada"
                                                    value={id_olimpiada}
                                                    onChange={(e) => setOlimpiada(e.target.value)}
                                                    disabled
                                                >
                                                    {olimpiadas.map(olimpiada => (
                                                        <option key={olimpiada.id} value={olimpiada.id}>
                                                            {olimpiada.nombre_olimpiada}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Unidad Educativa :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_unidadEducativa"
                                                    value={id_unidadEducativa}
                                                    onChange={(e) => setUnidadEducativa(e.target.value)}
                                                >
                                                    <option value="">Seleccione una unidad educativa</option>
                                                    {unidadesEducativas.map(unidad => (
                                                        <option key={unidad.id} value={unidad.id}>
                                                            {unidad.nombre_unidadEducativa}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Materia :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_materia"
                                                    value={id_materia}
                                                    onChange={(e) => setMateriaTutor(e.target.value)}
                                                >
                                                    <option value="">Seleccione una materia</option>
                                                    {materias.map(materia => (
                                                        <option key={materia.id} value={materia.id}>
                                                            {materia.nombre_materia}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Equipo :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_equipo"
                                                    value={id_equipo}
                                                    onChange={(e) => setEquipoTutor(e.target.value)}
                                                >
                                                    <option value="">Seleccione un equipo</option>
                                                    {equipos.map(equipo => (
                                                        <option key={equipo.id} value={equipo.id}>
                                                            {equipo.nombre_equipo}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddAsignacionTutor;
