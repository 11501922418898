import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ModalPdf from '../ModalPdf'; // Asegúrate de que la ruta sea correcta según tu estructura de archivos
import baseURL from "../../features/baseURL";

const ListaUnidadesEducativas = () => {
  const [unidadesEducativas, setUnidadesEducativas] = useState([]);
  const [filteredUnidadesEducativas, setFilteredUnidadesEducativas] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("nombre_unidadEducativa");
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false); // Estado para mostrar/ocultar modal

  useEffect(() => {
    getUnidadesEducativas();
  }, []);

  const getUnidadesEducativas = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/unidadesEducativas`);
      setUnidadesEducativas(response.data);
      setFilteredUnidadesEducativas(response.data);
    } catch (error) {
      console.error('Error fetching unidades educativas:', error);
    }
  };

  const deleteUnidadEducativa = async (unidadEducativaId) => {
    try {
      await axios.delete(`${baseURL.BASE_URL}/unidadesEducativas/delete_unidadEducativa/${unidadEducativaId}`);
      getUnidadesEducativas();
    } catch (error) {
      console.error('Error deleting unidad educativa:', error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterUnidadesEducativas(query, filterCriteria);
  };

  const filterUnidadesEducativas = (query, criteria) => {
    if (query) {
      const filtered = unidadesEducativas.filter(unidadEducativa => {
        let fieldValue;
        switch (criteria) {
          case 'nombre_unidadEducativa':
            fieldValue = unidadEducativa.nombre_unidadEducativa;
            break;
          case 'tiposUnidadEducativa.categoria_unidadEducativa':
            fieldValue = unidadEducativa.tiposUnidadEducativa.categoria_unidadEducativa;
            break;
          case 'municipio.nombre_municipio':
            fieldValue = unidadEducativa.municipio.nombre_municipio;
            break;
          case 'codigo_SIE_unidadEducativa':
            fieldValue = unidadEducativa.codigo_SIE_unidadEducativa;
            break;
          case 'olimpiada.nombre_olimpiada':
            fieldValue = unidadEducativa.olimpiada.nombre_olimpiada;
            break;
          default:
            break;
        }
        return fieldValue && String(fieldValue).toLowerCase().includes(query);
      });
      setFilteredUnidadesEducativas(filtered);
    } else {
      setFilteredUnidadesEducativas(unidadesEducativas);
    }
  };

  const toggleFilterDropdown = () => {
    setIsFilterDropdownOpen(!isFilterDropdownOpen);
  };

  const handleFilterChange = (criteria) => {
    setFilterCriteria(criteria);
    filterUnidadesEducativas(searchQuery, criteria);
    setIsFilterDropdownOpen(false);
  };

  const handlePrint = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Definir los encabezados y mapear los datos de las unidades educativas a estos encabezados
  const headers = ["No", "Nombre Unidad Educativa", "Categoría", "Municipio", "Código SIE", "Olimpiada"];
  const data = filteredUnidadesEducativas.map((unidadEducativa, index) => ({
    "No": index + 1,
    "Nombre Unidad Educativa": unidadEducativa.nombre_unidadEducativa,
    "Categoría": unidadEducativa.tiposUnidadEducativa.categoria_unidadEducativa,
    "Municipio": unidadEducativa.municipio.nombre_municipio,
    "Código SIE": unidadEducativa.codigo_SIE_unidadEducativa,
    "Olimpiada": unidadEducativa.olimpiada.nombre_olimpiada
  }));

  return (
    <div>
      <h1 className="title">Unidades Educativas</h1>
      <h2 className="subtitle">Lista de Unidades Educativas</h2>
      <div className="buttons">
        <Link to="/unidadesEducativas/add_unidadEducativa" className="button is-primary mb-2">Añadir Unidad Educativa</Link>
        <button onClick={handlePrint} className="button is-info mb-2">Imprimir</button>
      </div>
      
      <div className="field has-addons">
        <p className="control has-icons-left">
          <input
            type="text"
            className="input"
            placeholder={`Buscar por ${filterCriteria.replace('_', ' ')}`}
            value={searchQuery}
            onChange={handleSearch}
          />
          <span className="icon is-left">
            <i className="fas fa-search"></i>
          </span>
        </p>
        <div className={`control ${isFilterDropdownOpen ? 'is-active' : ''}`}>
          <div className={`dropdown ${isFilterDropdownOpen ? 'is-active' : ''}`}>
            <div className="dropdown-trigger">
              <button className="button" onClick={toggleFilterDropdown} aria-haspopup="true" aria-controls="dropdown-menu">
                <span className="icon is-small">
                  <i className="fas fa-filter"></i>
                </span>
                <span>Filtrar por</span>
                <span className="icon is-small">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            {isFilterDropdownOpen && (
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  <a className="dropdown-item" onClick={() => handleFilterChange('nombre_unidadEducativa')}>Nombre Unidad Educativa</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('tiposUnidadEducativa.categoria_unidadEducativa')}>Categoría</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('municipio.nombre_municipio')}>Municipio</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('codigo_SIE_unidadEducativa')}>Código SIE</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('olimpiada.nombre_olimpiada')}>Olimpiada</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Unidad Educativa</th>
            <th>Categoría</th>
            <th>Municipio</th>
            <th>Código SIE</th>
            <th>Olimpiada</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredUnidadesEducativas.map((unidadEducativa, index) => (
            <tr key={unidadEducativa.uuid_unidadEducativa}>
              <td>{index + 1}</td>
              <td>{unidadEducativa.nombre_unidadEducativa}</td>
              <td>{unidadEducativa.tiposUnidadEducativa.categoria_unidadEducativa}</td>
              <td>{unidadEducativa.municipio.nombre_municipio}</td>
              <td>{unidadEducativa.codigo_SIE_unidadEducativa}</td>
              <td>{unidadEducativa.olimpiada.nombre_olimpiada}</td>
              <td>
                <Link to={`/unidadesEducativas/update_unidadEducativa/${unidadEducativa.uuid_unidadEducativa}`} className="button is-small is-info mr-1">Editar</Link>
                <button onClick={() => deleteUnidadEducativa(unidadEducativa.uuid_unidadEducativa)} className="button is-small is-danger">Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <ModalPdf
          data={data}
          headers={headers}
          title="Lista de Unidades Educativas"
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default ListaUnidadesEducativas;
