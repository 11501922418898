import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    usuario: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
};

export const LogInUsuario = createAsyncThunk("usuario/LogInUsuario", async (usuario, thunkAPI) => {
    try {
        const response = await axios.post(`/login`, {
            correo: usuario.correo,
            password: usuario.password
        }, { withCredentials: true }); // Asegúrate de incluir withCredentials aquí también
        return response.data;
    } catch (error) {
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const getMe = createAsyncThunk("usuario/getMe", async (_, thunkAPI) => {
    try {
        const response = await axios.get(`/me`, { withCredentials: true }); // Asegúrate de incluir withCredentials aquí también
        return response.data;
    } catch (error) {
        if (error.response) {
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const LogOut = createAsyncThunk("usuario/LogOut", async () => {
    await axios.delete(`/logout`, { withCredentials: true }); // Asegúrate de incluir withCredentials aquí también
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(LogInUsuario.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(LogInUsuario.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.usuario = action.payload;
        });
        builder.addCase(LogInUsuario.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });

        // Get User LogIn
        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.usuario = action.payload;
        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });
    }
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
