import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const ListaTutores = () => {
  const [tutores, setTutores] = useState([]);

  useEffect(() => {
    getTutores();
  }, []);

  const getTutores= async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/tutores`);
    setTutores(response.data);
  };

  const deleteTutor = async (tutorId) => {
    await axios.delete(`${baseURL.BASE_URL}/tutores/delete_tutor/${tutorId}`);
    getTutores();
  };

  return (
    <div>
      <h1 className="title">Tutores</h1>
      <h2 className="subtitle">Lista de Tutores</h2>
      <Link to="/tutores/add_tutor" className="button is-primary mb-2">Añadir Tutor</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Tutor</th>
            <th>Correo Tutor</th>
            <th>Telefono Tutor</th>
            <th>Edad Tutor</th>
            <th>Olimpiada</th>
            <th>Accion</th>
          </tr>
        </thead>
        <tbody>
          {tutores.map((tutor, index) => (
            <tr key={tutor.uuid_tutor}>
              <td>{index + 1}</td>
              <td>{tutor.nombre_completo}</td>
              <td>{tutor.correo_tutor}</td>
              <td>{tutor.telefono_tutor}</td>
              <td>{tutor.edad_tutor}</td>
              <td>{tutor.olimpiada.nombre_olimpiada}</td>
              <td>
                <Link to={`/tutores/update_tutor/${tutor.uuid_tutor}`} className="button is-small is-info">Editar</Link>
                <Link to={`/tutoresAsignacion/add_tutorAsignacion/${tutor.uuid_tutor}`} className="button is-small is-dark">Asignar</Link>

                <button onClick={() => deleteTutor(tutor.uuid_tutor)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaTutores;
