import React, { useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogInUsuario, reset } from '../features/authSlice';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import '../styles/LogInStyle.css';
import logo from '../assets/logoOs.png';

const LogIn = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [correo,setCorreo]=useState("")
  const [password,setPassword]=useState("")
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {usuario,isError,isSuccess,isLoading,message}=useSelector(
        (state)=>state.auth
    );
    useEffect(()=>{
        if(usuario || isSuccess)
        {
            navigate("/dashboard");
        }
        dispatch(reset());

    },[usuario,isSuccess,dispatch,navigate])
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const Auth=(e)=>{
    e.preventDefault();
    dispatch(LogInUsuario({correo,password}));
  }

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4">
              <div onSubmit={Auth} className="box custom-box">
                {isError && <p className='has-text-centered'>{message}</p>}
                <div className="logo-box is-centered">
                  <img src={logo} alt="Logo" className="logo-image" />
                </div>
                <form>
                  <div className="field">
                    <label className="label">Correo :</label>
                    <div className="control">
                      <input type="text" className="input" id='correo' value={correo} onChange={(e)=>setCorreo(e.target.value)} placeholder="Ingrese su correo..." />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Contraseña:</label>
                    <div className="control has-icons-right">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="input"
                        id='password'
                        value={password} onChange={(e)=>setPassword(e.target.value)}
                        placeholder="Contraseña"
                      />
                      <span className="icon is-small is-right password-icon" onClick={togglePasswordVisibility}>
                        {passwordVisible ? <IoEyeOff /> : <IoEye />}
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <button type="submit" className="button is-primary is-fullwidth">
                   {isLoading  ? 'Cargando...': 'Acceder'}
                    </button>
                  </div>
                  <div className="field has-text-centered">
                    <a href="#forgot-password">¿Olvidó su contraseña?</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LogIn;
