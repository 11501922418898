import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddAsignacionRoles = () => {
    const navigate = useNavigate();
    const [usuarios, setUsuarios] = useState([]);
    const [filteredUsuarios, setFilteredUsuarios] = useState([]);
    const [selectedUsuario, setSelectedUsuario] = useState(null);
    const [nombre_completo_usuario, setNombreUsuario] = useState("");
    const [correo_usuario, setCorreoUsuario] = useState("");
    const [id_rol,setRol] = useState("");
    const [msg, setMsg] = useState("");
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getUsuarios();
        getRoles();
    }, []);

    const getUsuarios = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/usuarios`);
        setUsuarios(response.data);
    };

    const getRoles = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/roles`);
        setRoles(response.data);
    };


    const handleSearch = (e) => {
        const query = e.target.value;
        setNombreUsuario(query);
        if (query.length > 0) {
            const filtered = usuarios.filter(usuario =>
                usuario.nombre_completo.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredUsuarios(filtered);
        } else {
            setFilteredUsuarios([]);
        }
    };

    const handleSelectUsuario = (usuario) => {
        console.log(usuario.id)
        setSelectedUsuario(usuario.id);
        setNombreUsuario(usuario.nombre_completo);
        setCorreoUsuario(usuario.correo_usuario);
        setFilteredUsuarios([]);
    };

    const handleSubmit = async (e) => {
        console.log(id_rol)
        e.preventDefault();
        try {
            await axios.post(`${baseURL.BASE_URL}/rolesUsuarios/add_rolUsuario`, {
                id_usuario: selectedUsuario,
                id_rol
            });

            navigate("/rolesUsuarios");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Roles de Usuarios</h1>
            <h2 className="subtitle">Añadir Asignación de Roles</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        {msg && <p className="error-message">{msg}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nombre Completo :</label>
                                        <div className="control">
                                            <input
                                                type="text"
                                                className="input"
                                                name="nombre_completo_tutor"
                                                value={nombre_completo_usuario}
                                                onChange={handleSearch}
                                                placeholder="Buscar usuario por nombre..."
                                            />
                                            {filteredUsuarios.length > 0 && (
                                                <div className="dropdown is-active">
                                                    <ul className="dropdown-menu">
                                                        {filteredUsuarios.map((usuario) => (
                                                            <li
                                                                key={usuario.id}
                                                                onClick={() => handleSelectUsuario(usuario)}
                                                                className="dropdown-item"
                                                            >
                                                                {usuario.nombre_completo}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Correo :</label>
                                        <div className="control">
                                            <input
                                                type="email"
                                                className="input"
                                                name="correo_usuario"
                                                value={correo_usuario}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Rol :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="id_rol"
                                                    value={id_rol}
                                                    onChange={(e) => setRol(e.target.value)}
                                                >
                                                    {roles.map(rol => (
                                                        <option key={rol.id} value={rol.id}>
                                                            {rol.nombre_rol}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddAsignacionRoles;
