import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IoSearch, IoFilter } from 'react-icons/io5';
import '../../styles/ListaTutoresAsignaciones.css'; // Importar el archivo CSS
import baseURL from "../../features/baseURL";
import ModalPdf from '../ModalPdf'; // Asegúrate de que la ruta sea correcta según tu estructura de archivos

const ListaTutoresAsignaciones = () => {
  const [tutoresAsignaciones, setTutoresAsignaciones] = useState([]);
  const [filteredTutoresAsignaciones, setFilteredTutoresAsignaciones] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("nombre_completo");
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false); // Estado para mostrar/ocultar modal

  useEffect(() => {
    getTutoresAsignaciones();
  }, []);

  const getTutoresAsignaciones = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/tutoresAsignacion`);
      setTutoresAsignaciones(response.data);
      setFilteredTutoresAsignaciones(response.data);
    } catch (error) {
      console.error('Error fetching tutores asignaciones:', error);
    }
  };

  const deleteTutorAsignaciones = async (tutorAsignacionId) => {
    try {
      await axios.delete(`${baseURL.BASE_URL}/tutoresAsignacion/delete_tutorAsignacion/${tutorAsignacionId}`);
      getTutoresAsignaciones();
    } catch (error) {
      console.error('Error deleting tutor asignacion:', error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterTutoresAsignaciones(query, filterCriteria);
  };

  const filterTutoresAsignaciones = (query, criteria) => {
    if (query) {
      const filtered = tutoresAsignaciones.filter(tutorAsignacion => {
        let fieldValue;
        switch (criteria) {
          case 'nombre_completo':
            fieldValue = tutorAsignacion.tutore.nombre_completo;
            break;
          case 'correo_tutor':
            fieldValue = tutorAsignacion.tutore.correo_tutor;
            break;
          case 'telefono_tutor':
            fieldValue = tutorAsignacion.tutore.telefono_tutor;
            break;
          case 'olimpiada':
            fieldValue = tutorAsignacion.tutore.olimpiada.nombre_olimpiada;
            break;
          case 'unidad_educativa':
            fieldValue = tutorAsignacion.unidadesEducativa.nombre_unidadEducativa;
            break;
          case 'materia':
            fieldValue = tutorAsignacion.materia.nombre_materia;
            break;
          case 'equipo':
            fieldValue = tutorAsignacion.equipo.nombre_equipo;
            break;
          case 'categoria':
            fieldValue = tutorAsignacion.equipo.categoriasCompetencium.nombre_categoria;
            break;
          default:
            break;
        }
        return fieldValue && String(fieldValue).toLowerCase().includes(query);
      });
      setFilteredTutoresAsignaciones(filtered);
    } else {
      setFilteredTutoresAsignaciones(tutoresAsignaciones);
    }
  };

  const toggleFilterDropdown = () => {
    setIsFilterDropdownOpen(!isFilterDropdownOpen);
  };

  const handleFilterChange = (criteria) => {
    setFilterCriteria(criteria);
    filterTutoresAsignaciones(searchQuery, criteria);
    setIsFilterDropdownOpen(false);
  };

  const handlePrint = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Definir los encabezados y mapear los datos de las asignaciones de tutores a estos encabezados
  const headers = ["No", "Nombre Tutor", "Teléfono Tutor", "Olimpiada", "Unidad Educativa", "Materia", "Equipo", "Categoría"];
  const data = filteredTutoresAsignaciones.map((tutorAsignacion, index) => ({
    "No": index + 1,
    "Nombre Tutor": tutorAsignacion.tutore.nombre_completo,
    "Teléfono Tutor": tutorAsignacion.tutore.telefono_tutor,
    "Olimpiada": tutorAsignacion.tutore.olimpiada.nombre_olimpiada,
    "Unidad Educativa": tutorAsignacion.unidadesEducativa.nombre_unidadEducativa,
    "Materia": tutorAsignacion.materia.nombre_materia,
    "Equipo": tutorAsignacion.equipo.nombre_equipo,
    "Categoría": tutorAsignacion.equipo.categoriasCompetencium.nombre_categoria
  }));

  return (
    <div>
      <h1 className="title">Asignacion de Tutores</h1>
      <h2 className="subtitle">Lista de Asignaciones de Tutores</h2>
      <div className="buttons">
        <Link to="/tutoresAsignacion/add_tutorAsignacion" className="button is-primary mb-2">Añadir Asignacion de Tutor</Link>
        <button onClick={handlePrint} className="button is-info mb-2">Imprimir</button>
      </div>
      <div className="field has-addons">
        <p className="control has-icons-left">
          <input
            type="text"
            className="input"
            placeholder={`Buscar por ${filterCriteria.replace('_', ' ')}`}
            value={searchQuery}
            onChange={handleSearch}
          />
          <span className="icon is-left">
            <IoSearch />
          </span>
        </p>
        <div className={`control ${isFilterDropdownOpen ? 'is-active' : ''}`}>
          <div className={`dropdown ${isFilterDropdownOpen ? 'is-active' : ''}`}>
            <div className="dropdown-trigger">
              <button className="button" onClick={toggleFilterDropdown} aria-haspopup="true" aria-controls="dropdown-menu">
                <span className="icon is-small">
                  <IoFilter />
                </span>
              </button>
            </div>
            {isFilterDropdownOpen && (
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  <a className="dropdown-item" onClick={() => handleFilterChange('nombre_completo')}>Nombre</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('correo_tutor')}>Correo</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('telefono_tutor')}>Teléfono</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('olimpiada')}>Olimpiada</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('unidad_educativa')}>Unidad Educativa</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('materia')}>Materia</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('equipo')}>Equipo</a>
                  <a className="dropdown-item" onClick={() => handleFilterChange('categoria')}>Categoría</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Tutor</th>
            <th>Teléfono Tutor</th>
            <th>Olimpiada</th>
            <th>Unidad Educativa</th>
            <th>Materia</th>
            <th>Equipo</th>
            <th>Categoría</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {filteredTutoresAsignaciones.map((tutorAsignacion, index) => (
            <tr key={tutorAsignacion.uuid_tutorAsignacion}>
              <td>{index + 1}</td>
              <td>{tutorAsignacion.tutore.nombre_completo}</td>
              <td>{tutorAsignacion.tutore.telefono_tutor}</td>
              <td>{tutorAsignacion.tutore.olimpiada.nombre_olimpiada}</td>
              <td>{tutorAsignacion.unidadesEducativa.nombre_unidadEducativa}</td>
              <td>{tutorAsignacion.materia.nombre_materia}</td>
              <td>{tutorAsignacion.equipo.nombre_equipo}</td>
              <td>{tutorAsignacion.equipo.categoriasCompetencium.nombre_categoria}</td>
              <td>
                <button onClick={() => deleteTutorAsignaciones(tutorAsignacion.uuid_tutorAsignacion)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <ModalPdf
        data={data}
        headers={headers}
        title="Lista de Tutores Asignados"
        closeModal={closeModal}
      />
      )}
    </div>
  );
};

export default ListaTutoresAsignaciones;
