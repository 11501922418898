import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IoBusiness, IoDocumentAttach, IoList, IoPerson, IoHome, IoMap, IoLogOut, IoSchoolSharp, IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogOut, reset } from '../features/authSlice';
import UserProfile from './userProfile'; // Importa el nuevo componente
import "../styles/SideBarStyle.css";

const Sidebar = () => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isSubmenuOpen2, setIsSubmenuOpen2] = useState(false);
  const [isSubmenuOpen3, setIsSubmenuOpen3] = useState(false);
  const [isSubmenuOpen4, setIsSubmenuOpen4] = useState(false);
  const [isSubmenuOpen5, setIsSubmenuOpen5] = useState(false);
  const [isSubmenuOpen6, setIsSubmenuOpen6] = useState(false);

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const toggleSubmenu2 = () => {
    setIsSubmenuOpen2(!isSubmenuOpen2);
  };
  
  const toggleSubmenu3 = () => {
    setIsSubmenuOpen3(!isSubmenuOpen3);
  };
  const toggleSubmenu4 = () => {
    setIsSubmenuOpen4(!isSubmenuOpen4);
  };
  const toggleSubmenu5 = () => {
    setIsSubmenuOpen5(!isSubmenuOpen5);
  };
  const toggleSubmenu6 = () => {
    setIsSubmenuOpen6(!isSubmenuOpen6);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usuario } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div>
      <aside className="menu pl-2 has-shadow">
        <UserProfile /> {/* Añade el componente de perfil de usuario aquí */}
        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li>
            <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'is-active' : ''}>
              <IoHome /> Dashboard
            </NavLink>
          </li>
          <li><NavLink to="#" onClick={toggleSubmenu6}>
              <IoSchoolSharp /> Participantes {isSubmenuOpen6 ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
            </NavLink>
            
          
          {isSubmenuOpen6 && (
              <ul className="submenu">
                    <li>
                      <NavLink to="/municipios" className={({ isActive }) => isActive ? 'is-active' : ''}>
                        <IoMap /> Municipios
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/unidadesEducativas" className={({ isActive }) => isActive ? 'is-active' : ''}>
                        <IoBusiness /> Unidades Educativas
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/estudiantes" className={({ isActive }) => isActive ? 'is-active' : ''}>
                        <IoBusiness /> Estudiantes
                      </NavLink>
                    </li>
                    <li>
            <NavLink to="#" onClick={toggleSubmenu2}>
              <IoSchoolSharp /> Tutores {isSubmenuOpen2 ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
            </NavLink>
            {isSubmenuOpen2 && (
              <ul className="submenu">
                <li>
                  <NavLink to="/tutoresAsignacion" className={({ isActive }) => isActive ? 'is-active' : ''}>
                  <IoDocumentAttach/> Asignacion de Tutores
                  </NavLink>
                </li>
               
                <li>
                  <NavLink to="/tutores" className={({ isActive }) => isActive ? 'is-active' : ''}>
                   <IoList/> Lista de Tutores
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
              </ul>
          )}
          </li>
          
          
          <li>
          <NavLink to="#" onClick={toggleSubmenu3}>
              <IoSchoolSharp /> Cursos {isSubmenuOpen3 ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
            </NavLink>
            {isSubmenuOpen3 && (
              <ul className="submenu">
                <li>
                  <NavLink to="/cursos" className={({ isActive }) => isActive ? 'is-active' : ''}>
                  <IoDocumentAttach/> Cursos
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/niveles" className={({ isActive }) => isActive ? 'is-active' : ''}>
                   <IoList/> Niveles
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/cursosConformados" className={({ isActive }) => isActive ? 'is-active' : ''}>
                   <IoList/> Lista de Cursos Conformados
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <NavLink to="#" onClick={toggleSubmenu4}>
              <IoSchoolSharp /> Competencia {isSubmenuOpen4 ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
            </NavLink>
            {isSubmenuOpen4 && (
              <ul className="submenu">
                <li>
                  <NavLink to="/equipos" className={({ isActive }) => isActive ? 'is-active' : ''}>
                  <IoDocumentAttach/> Equipos
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/categoriasCompetencia" className={({ isActive }) => isActive ? 'is-active' : ''}>
                   <IoList/> Categorias Competencia
                  </NavLink>
                </li>
                <NavLink to="#" onClick={toggleSubmenu5}>
                  <IoSchoolSharp /> Retos Calificadores{isSubmenuOpen5 ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
                </NavLink>
                {isSubmenuOpen5 && (
                  <ul className="submenu">
                        <li>
                        <NavLink to="/retosCalificadores" className={({ isActive }) => isActive ? 'is-active' : ''}>
                        <IoList/> Lista Retos Calificadores
                        </NavLink>
                       </li>
                       <li>
                        <NavLink to="/entregasRetosCalificadores" className={({ isActive }) => isActive ? 'is-active' : ''}>
                        <IoList/> Entregas
                        </NavLink>
                       </li>
                  </ul>
                )}
               
              </ul>
            )}
          </li>
        </ul>
        {usuario && usuario.roles_usuario[0] === "Admin" && (
          <div>
            <p className="menu-label">Admin</p>
            <ul className="menu-list">
              <li>
                <NavLink to="/usuarios" className={({ isActive }) => isActive ? 'is-active' : ''}>
                  <IoPerson style={{ color: 'black' }} /> Usuarios
                </NavLink>
              </li>
              <li>
                <NavLink to="#" onClick={toggleSubmenu}>
                  <IoPerson /> Roles  {isSubmenuOpen ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
                </NavLink>
                {isSubmenuOpen && (
                  <ul className="submenu">
                    <li>
                      <NavLink to="/rolesUsuarios" className={({ isActive }) => isActive ? 'is-active' : ''}>
                      <IoDocumentAttach/> Asignacion de Roles
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/roles" className={({ isActive }) => isActive ? 'is-active' : ''}>
                      <IoList/> Lista de Roles
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        )}
        <p className="menu-label">Configuraciones</p>
        <ul className="menu-list">
          <li>
            <button onClick={logout} className='button is-white'>
              <IoLogOut /> LogOut
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
