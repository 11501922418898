import React, { useState } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import "../../styles/AddUsuarioStyle.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddRoles = () => {
   const [nombre_rol,setNombreRol]=useState("")
   const [msg,setMsg]=useState("")
   const navigate=useNavigate()
   
   const saveRol=async(e)=>{
    e.preventDefault();
    try {
        await axios.post(`${baseURL.BASE_URL}/roles/add_rol`,{
            nombre_rol:nombre_rol
        })
        navigate("/roles")
    } catch (error) {
        if(error.response)
            {
                setMsg(error.response.data.msg);
            }
    }
   }
    return (
        <div>
            <h1 className="title">Roles</h1>
            <h2 className="subtitle">Añadir nuevo rol</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={saveRol}>
                            <p className='has-text-centered'>{msg}</p>
                            <div className="field">
                                <label className="label">Rol :</label>
                                <div className="control">
                                    <input type="text" className="input" value={nombre_rol} onChange={(e)=>setNombreRol(e.target.value)} placeholder="Ingrese el rol..." />
                                </div>
                            </div>      
                               

                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddRoles;
