import React,{useEffect} from 'react'
import Layout from '../Layout'
import FormEditUnidadEducativa from "../../components/UnidadesEducativas/FormEditUnidadEducativa"
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../../features/authSlice'
const EditUnidadEducativa = () => {
    const dispatch =useDispatch();
    const navigate=useNavigate();
    const {isError}=useSelector((state=>state.auth));
    useEffect(()=>{
        dispatch(getMe());
    },[dispatch])
    useEffect(()=>{
        if(isError)
         {
             navigate("/")
         }
        
     },[isError,navigate])
  return (
    <Layout>
        <FormEditUnidadEducativa/>
    </Layout>
  )
}

export default EditUnidadEducativa
