import React from 'react';
import { NavLink,useNavigate } from "react-router-dom";
import logo from "../assets/logoOs.png";
import '../styles/NavbarStyle.css'; // Asegúrate de importar tu archivo CSS
import { useDispatch,useSelector } from 'react-redux'
import { LogOut,getMe, reset } from '../features/authSlice'

const Navbar = () => {
  const navigate=useNavigate();
  const dispatch=useDispatch();

    const {usuario}=useSelector((state)=>state.auth)
    const logout=()=>{
      dispatch(LogOut());
      dispatch(reset());
      navigate("/")
    }
  return (
    <nav className="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <NavLink to="/dashboard" className="navbar-item">
          <img 
            src={logo}
            className="navbar-logo"
            alt="logo"
          />
        </NavLink>
        
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <button onClick={logout} className="button is-light">
                Cerrar Sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
