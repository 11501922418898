import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const ListaEstudiantes = () => {
  const [estudiantes, setEstudiantes] = useState([]);

  useEffect(() => {
    getEstudiantes();
  }, []);

  const getEstudiantes= async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/estudiantes`);
    setEstudiantes(response.data);
  };

  const deleteEstudiante = async (estudianteId) => {
    await axios.delete(`${baseURL.BASE_URL}/estudiantes/delete_estudiante/${estudianteId}`);
    getEstudiantes();
  };

  return (
    <div>
      <h1 className="title">Estudiantes</h1>
      <h2 className="subtitle">Lista de Estudiantes</h2>
      <Link to="/estudiantes/add_estudiante" className="button is-primary mb-2">Añadir Estudiante</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Estudiante</th>
            <th>Edad Estudiante</th>
            <th>Genero Estudiante</th>
            <th>Curso Estudiante</th>
            <th>Unidad Educativa</th>
            <th>Municipio</th>
            <th>Equipo</th>
            <th>Categoria</th>
            
            <th>Olimpiada</th>
            <th>Accion</th>
          </tr>
        </thead>
        <tbody>
          {estudiantes.map((estudiante, index) => (
            <tr key={estudiante.uuid_estudiante}>
              <td>{index + 1}</td>
              <td>{estudiante.nombre_completo}</td>
              <td>{estudiante.edad_estudiante}</td>
              <td>{estudiante.genero.nombre_genero}</td>
              <td>{estudiante.cursosConformado.curso.nombre_curso + " " + estudiante.cursosConformado.nivele.nombre_nivel}</td>
              <td>{estudiante.unidadesEducativa.nombre_unidadEducativa}</td>
              <td>{estudiante.unidadesEducativa.municipio.nombre_municipio}</td>
              <td>{estudiante.equipo.nombre_equipo}</td>
              <td>{estudiante.equipo.categoriasCompetencium.nombre_categoria}</td>
              <td>{estudiante.olimpiada.nombre_olimpiada}</td>



              <td>
                <Link to={`/estudiantes/update_estudiante/${estudiante.uuid_estudiante}`} className="button is-small is-info">Editar</Link>

                <button onClick={() => deleteEstudiante(estudiante.uuid_estudiante)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaEstudiantes;
