import React,{useEffect} from 'react'
import Layout from '../Layout'
import ListaUsuarios from '../../components/Usuarios/ListaUsuarios'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../../features/authSlice'
const Usuarios = () => {
  const dispatch =useDispatch();
    const navigate=useNavigate();
    const {isError,usuario}=useSelector((state=>state.auth));
    useEffect(()=>{
        dispatch(getMe());
    },[dispatch])
    useEffect(()=>{
       if(isError)
        {
            navigate("/")
        }
        if(usuario && usuario.roles_usuario[0]!=="Admin")
          {
            navigate("/dashboard")
          }
    },[isError,usuario,navigate])
  return (
    <Layout>
        <ListaUsuarios/>
    </Layout>
  )
}

export default Usuarios
