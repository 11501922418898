import React from 'react';
import { useSelector } from 'react-redux';
import "../styles/userprofileStyle.css"; // Asegúrate de crear este archivo CSS
import baseURL from "../features/baseURL"
const UserProfile = () => {
  const { usuario } = useSelector((state) => state.auth);

  if (!usuario) return null;

  // Asegúrate de que la URL base apunte correctamente a tu backend

  return (
    <div className="user-profile">
      <img src={`${baseURL.BASE_URL}/uploads/images/${usuario.imagen_usuario}`} alt="User" className="user-image" />
      <div className="user-info">
        <h3><stroke>{usuario.nombre_usuario}</stroke></h3>
        <p>{usuario.roles_usuario[0]}</p>
        <p className="status">
          <span className="status-indicator"></span>En línea
        </p>
      </div>
    </div>
  );
};

export default UserProfile;
