import React,{useEffect} from 'react'
import Layout from '../Layout'
import FormAddUnidadEducativa from '../../components/UnidadesEducativas/FormAddUnidadEducativa'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../../features/authSlice'
const AddUnidadEducativa = () => {
    const dispatch =useDispatch();
    const navigate=useNavigate();
    const {isError}=useSelector((state=>state.auth));
    useEffect(()=>{
        dispatch(getMe());
    },[dispatch])
    useEffect(()=>{
        if(isError)
         {
             navigate("/")
         }
         
     },[isError,navigate])
  return (
    <Layout>
        <FormAddUnidadEducativa/>
    </Layout>
  )
}

export default AddUnidadEducativa
