import React, { useState,useEffect } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import "../../styles/AddUsuarioStyle.css"
import { useNavigate,useParams} from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormEditRoles = () => {
    const [nombre_rol,setNombreRol]=useState("")
   const [msg,setMsg]=useState("")
   const navigate=useNavigate()
   const {id}=useParams();
   useEffect(()=>{
        const getRolById=async()=>
            {
                try {
                    const response=await axios.get(`${baseURL.BASE_URL}/roles/${id}`)
                    setNombreRol(response.data.nombre_rol);
                    
                } catch (error) {
                    if(error.response)
                        {
                            setMsg(error.response.data.msg);
                        }
                }
            }
            getRolById();
   },[id])
   const updateRol=async(e)=>{
    e.preventDefault();
    try {
        await axios.patch(`${baseURL.BASE_URL}/roles/update_rol/${id}`,{
            nombre_rol:nombre_rol
        })
        navigate("/roles")
    } catch (error) {
        if(error.response)
            {
                setMsg(error.response.data.msg);
            }
    }
   }
   
    return (
        <div>
            <h1 className="title">Roles</h1>
            <h2 className="subtitle">Editar Rol</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={updateRol}>
                            <p className='has-text-centered'>{msg}</p>
                            <div className="field">
                                <label className="label">Rol :</label>
                                <div className="control">
                                    <input type="text" className="input" value={nombre_rol} onChange={(e)=>setNombreRol(e.target.value)} placeholder="Ingrese el rol..." />
                                </div>
                            </div>      
                               

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Actualizar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormEditRoles;
