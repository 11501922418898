import React, { useState } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import "../../styles/AddUsuarioStyle.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddCursos = () => {
   const [nombre_curso,setNombreCurso]=useState("")
   const [msg,setMsg]=useState("")
   const navigate=useNavigate()
   
   const guardarCurso=async(e)=>{
    e.preventDefault();
    try {
        await axios.post(`${baseURL.BASE_URL}/cursos/add_curso`,{
            nombre_curso:nombre_curso
        })
        navigate("/cursos")
    } catch (error) {
        if(error.response)
            {
                setMsg(error.response.data.msg);
            }
    }
   }
    return (
        <div>
            <h1 className="title">Cursos</h1>
            <h2 className="subtitle">Añadir nuevo Curso</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={guardarCurso}>
                            <p className='has-text-centered'>{msg}</p>
                            <div className="field">
                                <label className="label">Curso :</label>
                                <div className="control">
                                    <input type="text" className="input" value={nombre_curso} onChange={(e)=>setNombreCurso(e.target.value)} placeholder="Ingrese el curso..." />
                                </div>
                            </div>      
                               

                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddCursos;
