import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddEquipo = () => {
    const navigate = useNavigate();
    const [nombre_equipo, setNombreEquipo] = useState("");
    const [id_categoriaCompetencia, setCategoriaCompetencia] = useState("");
    const [id_unidadEducativa, setUnidadEducativa] = useState("");
    const [id_olimpiada, setOlimpiada] = useState("");
    

    const [categoriasCompetencia,setCategoriasCompetencia]=useState([])
    const [unidadesEducativas,setUnidadesEducativas]=useState([])
    const [olimpiadas,setOlimpiadas]=useState([])

    const [msg, setMsg] = useState("");
    useEffect(()=>{
        getOlimpiadas();
        getCategoriasCompetencia();
        getUnidadesEducativas();
    },[]);
    const getOlimpiadas=async()=>{
        const response=await axios.get(`${baseURL.BASE_URL}/olimpiadas`);
        setOlimpiadas(response.data)
    };
    const getCategoriasCompetencia=async()=>{
        const response=await axios.get(`${baseURL.BASE_URL}/categoriasCompetencia`)
        setCategoriasCompetencia(response.data)
    };
    
    const getUnidadesEducativas=async()=>{
        const response=await axios.get(`${baseURL.BASE_URL}/unidadesEducativas`)
        setUnidadesEducativas(response.data)
    }
    


    const handleSubmit = async (e) => {
        
        e.preventDefault();
        try {
            const equiposResponse = await axios.post(`${baseURL.BASE_URL}/equipos/add_equipo`, {
                nombre_equipo,
                id_categoriaCompetencia,
                id_unidadEducativa,
                id_olimpiada

            });

            navigate("/equipos");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Equipos</h1>
            <h2 className="subtitle">Añadir equipos</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        {msg && <p className="error-message">{msg}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nombre del equipo:</label>
                                        <div className="control">
                                            <input type="text" className="input" name="nombre_equipo" value={nombre_equipo} onChange={(e) => setNombreEquipo(e.target.value)} placeholder="Ingrese el nombre del equipo..." />
                                        </div>
                                    </div>
                                    
                                    <div className="field">
                                        <label className="label">Unidad Educativa  :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_unidadEducativa" value={id_unidadEducativa} onChange={(e) => setUnidadEducativa(e.target.value)}>
                                                    {unidadesEducativas.map(unidadEducativa => (
                                                        <option key={unidadEducativa.id} value={unidadEducativa.id}>{unidadEducativa.nombre_unidadEducativa}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                            
                                </div>
                                <div className="column">
                                    
                                    <div className="field">
                                        <label className="label">Categorias Competencia:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_categoriaCompetencia" value={id_categoriaCompetencia} onChange={(e) => setCategoriaCompetencia(e.target.value)}>
                                                    {categoriasCompetencia.map(categoriaCompetencia => (
                                                        <option key={categoriaCompetencia.id} value={categoriaCompetencia.id}>{categoriaCompetencia.nombre_categoria}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Olimpiada:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_olimpiada" value={id_olimpiada} onChange={(e) => setOlimpiada(e.target.value)}>
                                                    {olimpiadas.map(olimpiada => (
                                                        <option key={olimpiada.id} value={olimpiada.id}>{olimpiada.nombre_olimpiada}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddEquipo
