import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IoSearch, IoFilter } from 'react-icons/io5';
import '../../styles/ModalStyles.css';
import baseURL from "../../features/baseURL";
import ModalPdf from '../ModalPdf';

const ListaEntregasRetosCalificadores = () => {
    const [entregasRetosCalificadores, setEntregasRetosCalificadores] = useState([]);
    const [filteredEntregas, setFilteredEntregas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedEntrega, setSelectedEntrega] = useState(null);
    const [pdfSrc, setPdfSrc] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [filterField, setFilterField] = useState("nombre_equipo");
    const [selectedReto, setSelectedReto] = useState("todos");
    const [retos, setRetos] = useState([]);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [sortOrder, setSortOrder] = useState("asc");

    useEffect(() => {
        getEntregasRetosCalificadores();
        getRetos();
    }, []);

    useEffect(() => {
        filterAndSortEntregas();
    }, [entregasRetosCalificadores, searchTerm, filterField, selectedReto, sortOrder]);

    const getEntregasRetosCalificadores = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/entregasRetosCalificadores`);
        setEntregasRetosCalificadores(response.data);
        setFilteredEntregas(response.data);
    };

    const getRetos = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/retosCalificadores`);
        setRetos(response.data);
    };

    const getEntregasRetosCalificadoresById = async (uuid_entrega) => {
        const response = await axios.get(`${baseURL.BASE_URL}/entregasRetosCalificadores/${uuid_entrega}`);
        return response.data;
    };

    const deleteEntrega = async (uuid_entrega) => {
        await axios.delete(`${baseURL.BASE_URL}/entregasRetosCalificadores/delete_entrega/${uuid_entrega}`);
        getEntregasRetosCalificadores();
    };

    const openModal = async (entrega) => {
        const entregaData = await getEntregasRetosCalificadoresById(entrega.uuid_entregaRetoCalificador);
        setSelectedEntrega(entregaData);
        setPdfSrc(`${baseURL.BASE_URL}/uploads/documents/${entregaData.ruta_documento_entregado_retoCalificador}`);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedEntrega(null);
        setPdfSrc("");
    };

    const filterAndSortEntregas = () => {
        let filtered = entregasRetosCalificadores;

        if (selectedReto !== "todos") {
            const retoId = parseInt(selectedReto, 10);
            filtered = filtered.filter(entrega => entrega.retosCalificadore.id === retoId);
        }

        if (searchTerm) {
            filtered = filtered.filter(entrega => {
                if (filterField === "nombre_equipo") {
                    return entrega.equipo.nombre_equipo.toLowerCase().includes(searchTerm.toLowerCase());
                } else if (filterField === "nombre_unidadEducativa") {
                    return entrega.equipo.unidadesEducativa.nombre_unidadEducativa.toLowerCase().includes(searchTerm.toLowerCase());
                } else if (filterField === "nombre_categoria") {
                    return entrega.equipo.categoriasCompetencium.nombre_categoria.toLowerCase().includes(searchTerm.toLowerCase());
                } else if (filterField === "estado_entregado_retoCalificador") {
                    return entrega.estado_entregado_retoCalificador.toLowerCase().includes(searchTerm.toLowerCase());
                }
                return true;
            });
        }

        // Ordenar
        filtered.sort((a, b) => {
            let valueA, valueB;
            if (filterField === "nombre_equipo") {
                valueA = a.equipo.nombre_equipo;
                valueB = b.equipo.nombre_equipo;
            } else if (filterField === "nombre_unidadEducativa") {
                valueA = a.equipo.unidadesEducativa.nombre_unidadEducativa;
                valueB = b.equipo.unidadesEducativa.nombre_unidadEducativa;
            } else if (filterField === "nombre_categoria") {
                valueA = a.equipo.categoriasCompetencium.nombre_categoria;
                valueB = b.equipo.categoriasCompetencium.nombre_categoria;
            } else if (filterField === "estado_entregado_retoCalificador") {
                valueA = a.estado_entregado_retoCalificador;
                valueB = b.estado_entregado_retoCalificador;
            }

            if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
            if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });

        setFilteredEntregas(filtered);
    };

    const handlePrint = () => {
        setShowPdfModal(true);
    };

    const closePdfModal = () => {
        setShowPdfModal(false);
    };

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const headers = ["No", "Unidad Educativa", "Equipo", "Categoría", "Fecha Entrega", "Hora Entrega", "Estado", "Reto Calificador", "Olimpiada"];
    const data = filteredEntregas.map((entrega, index) => ({
        "No": index + 1,
        "Unidad Educativa": entrega.equipo.unidadesEducativa.nombre_unidadEducativa,
        "Equipo": entrega.equipo.nombre_equipo,
        "Categoría": entrega.equipo.categoriasCompetencium.nombre_categoria,
        "Fecha Entrega": entrega.fecha_entregado_retoCalificador,
        "Hora Entrega": entrega.hora_entregado_retoCalificador,
        "Estado": entrega.estado_entregado_retoCalificador,
        "Reto Calificador": entrega.retosCalificadore.nombre_retoCalificador,
        "Olimpiada": entrega.olimpiada.nombre_olimpiada
    }));

    return (
        <div className="container p-5">
            <h1 className="title">Entregas de Retos Calificadores</h1>
            <h2 className="subtitle">Lista de Entregas</h2>
            <div className="buttons">
                <Link to="/entregasRetosCalificadores/add_entregaRetoCalificador" className="button is-primary mb-2">Añadir Entrega</Link>
                <Link to="/entregasRetosCalificadores/tablaGeneral" className="button is-primary mb-2">Centralizador</Link>
                <button onClick={handlePrint} className="button is-info mb-2">Imprimir</button>
            </div>

            <div className="field is-grouped">
                <div className="control is-expanded">
                    <input
                        className="input"
                        type="text"
                        placeholder="Buscar..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="control">
                    <div className="select">
                        <select value={filterField} onChange={(e) => setFilterField(e.target.value)}>
                            <option value="nombre_equipo">Equipo</option>
                            <option value="nombre_unidadEducativa">Unidad Educativa</option>
                            <option value="nombre_categoria">Categoría</option>
                            <option value="estado_entregado_retoCalificador">Estado</option>
                        </select>
                    </div>
                </div>
                <div className="control">
                    <div className="select">
                        <select value={selectedReto} onChange={(e) => setSelectedReto(e.target.value)}>
                            <option value="todos">Todos los Retos</option>
                            {retos.map(reto => (
                                <option key={reto.id} value={reto.id.toString()}>{reto.nombre_retoCalificador}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="control">
                    <button className="button" onClick={toggleSortOrder}>
                        {sortOrder === "asc" ? "Ascendente" : "Descendente"}
                    </button>
                </div>
            </div>

            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nombre Unidad Educativa</th>
                        <th>Nombre Equipo</th>
                        <th>Categoria Competencia</th>
                        <th>Fecha Entrega</th>
                        <th>Hora Entregado</th>
                        <th>Estado</th>
                        <th>Reto Calificador</th>
                        <th>Olimpiada</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEntregas.map((entrega, index) => (
                        <tr key={entrega.uuid_entregaRetoCalificador}>
                            <td>{index + 1}</td>
                            <td>{entrega.equipo.unidadesEducativa.nombre_unidadEducativa}</td>
                            <td>{entrega.equipo.nombre_equipo}</td>
                            <td>{entrega.equipo.categoriasCompetencium.nombre_categoria}</td>
                            <td>{entrega.fecha_entregado_retoCalificador}</td>
                            <td>{entrega.hora_entregado_retoCalificador}</td>
                            <td>{entrega.estado_entregado_retoCalificador}</td>
                            <td>{entrega.retosCalificadore.nombre_retoCalificador}</td>
                            <td>{entrega.olimpiada.nombre_olimpiada}</td>
                            <td>
                                <button onClick={() => deleteEntrega(entrega.uuid_entregaRetoCalificador)} className="button is-small is-danger">Borrar</button>
                                <button onClick={() => openModal(entrega)} className="button is-small is-primary">Ver Detalles</button>
                                <Link to={`/calificaciones_retosCalificadores/add_entregaRetoCalificador/${entrega.uuid_entregaRetoCalificador}`} className="button is-small is-info">Calificar</Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={closeModal}></div>
                    <div className="modal-content custom-modal modal-lg">
                        <div className="box">
                            <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
                            <h5 className="modal-title text-danger">Detalles de la Entrega</h5>
                            
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nombre Equipo</label>
                                        <input className="input" type="text" value={selectedEntrega?.equipo.nombre_equipo} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Fecha Entrega</label>
                                        <input className="input" type="date" value={selectedEntrega?.fecha_entregado_retoCalificador} readOnly />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Estado</label>
                                        <input className="input" type="text" value={selectedEntrega?.estado_entregado_retoCalificador} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Reto Calificador</label>
                                        <input className="input" type="text" value={selectedEntrega?.retosCalificadore.nombre_retoCalificador} readOnly />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Olimpiada</label>
                                        <input className="input" type="text" value={selectedEntrega?.olimpiada.nombre_olimpiada} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Hora Entregado</label>
                                        <input className="input" type="time" value={selectedEntrega?.hora_entregado_retoCalificador} readOnly />
                                    </div>
                                </div>
                            </div>

                            <h5 className="modal-title text-danger mt-4 descripcion2">PDF de la Entrega</h5>
                            <iframe 
                                src={pdfSrc} 
                                width="100%" 
                                height="500px" 
                                title="PDF Viewer"
                                style={{ border: 'none' }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}

            {showPdfModal && (
                <ModalPdf
                    data={data}
                    headers={headers}
                    title="Lista de Entregas de Retos Calificadores"
                    closeModal={closePdfModal}
                />
            )}
        </div>
    );
};

export default ListaEntregasRetosCalificadores;