import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL";

const FormAddEntregasRetosCalificadores = () => {
    const [pdfLink, setPdfLink] = useState("");
    const [olimpiadas, setOlimpiadas] = useState([]);
    const [id_retoCalificador, setIdRetoCalificador] = useState("");
    const [id_equipo, setIdEquipo] = useState("");
    const [fecha_entregado_retoCalificador, setFechaEntregadoRetoCalificador] = useState("");
    const [hora_entregado_retoCalificador, setHoraEntregadoRetoCalificador] = useState("");
    const [id_olimpiada, setIdOlimpiada] = useState("");
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [unidadesEducativas, setUnidadesEducativas] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [id_unidadEducativa, setUnidadEducativa] = useState("");
    const [retosCalificadores, setRetosCalificadores] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getOlimpiadas();
        getUnidadesEducativas();
        getRetosCalificadores();
    }, []);

    useEffect(() => {
        if (id_unidadEducativa) {
            getEquiposByUnidadEducativa(id_unidadEducativa);
        }
    }, [id_unidadEducativa]);

    const getRetosCalificadores = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/retosCalificadores`);
        setRetosCalificadores(response.data);
    };

    const getOlimpiadas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/olimpiadas`);
        setOlimpiadas(response.data);
    };

    const getUnidadesEducativas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/unidadesEducativas`);
        setUnidadesEducativas(response.data);
    };

    const getEquiposByUnidadEducativa = async (unidadEducativaId) => {
        const response = await axios.get(`${baseURL.BASE_URL}/equipos/unidadEducativa/${unidadEducativaId}`);
        setEquipos(response.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const data = {
                id_retoCalificador,
                id_equipo,
                fecha_entregado_retoCalificador,
                hora_entregado_retoCalificador,
                id_olimpiada,
                pdf_link: pdfLink
            };

            const response = await axios.post(`${baseURL.BASE_URL}/entregasRetosCalificadores/add_entregaRetoCalificador`, data);
            setMsg("Entrega de Reto Calificador añadida con éxito");
            navigate("/entregasRetosCalificadores");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            } else {
                setMsg("Ocurrió un error al enviar el formulario");
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <h1 className="title">Lista Entregas Retos Calificadores</h1>
            <h2 className="subtitle">Añadir nueva entrega de reto calificador</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Retos Calificadores:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select 
                                                    name="id_retoCalificador" 
                                                    value={id_retoCalificador} 
                                                    onChange={(e) => setIdRetoCalificador(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Seleccione un Reto Calificador</option>
                                                    {retosCalificadores.map(retoCalificador => (
                                                        <option key={retoCalificador.id} value={retoCalificador.id}>
                                                            {retoCalificador.nombre_retoCalificador}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Fecha de Entrega :</label>
                                        <div className="control">
                                            <input 
                                                type="date" 
                                                className="input" 
                                                name="fecha_entregado_retoCalificador" 
                                                onChange={(e) => setFechaEntregadoRetoCalificador(e.target.value)}
                                                required 
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Hora de Entrega :</label>
                                        <div className="control">
                                            <input 
                                                type="time" 
                                                className="input" 
                                                name="hora_entregado_retoCalificador" 
                                                onChange={(e) => setHoraEntregadoRetoCalificador(e.target.value)}
                                                required 
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Equipo:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select 
                                                    name="id_equipo" 
                                                    value={id_equipo} 
                                                    onChange={(e) => setIdEquipo(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Seleccione un equipo</option>
                                                    {equipos.map(equipo => (
                                                        <option key={equipo.id} value={equipo.id}>{equipo.nombre_equipo}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="field">
                                        <label className="label">Unidad Educativa  :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select 
                                                    name="id_unidadEducativa" 
                                                    value={id_unidadEducativa} 
                                                    onChange={(e) => setUnidadEducativa(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Seleccione una unidad educativa</option>
                                                    {unidadesEducativas.map(unidadEducativa => (
                                                        <option key={unidadEducativa.id} value={unidadEducativa.id}>{unidadEducativa.nombre_unidadEducativa}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Olimpiada :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select 
                                                    name="id_olimpiada" 
                                                    value={id_olimpiada} 
                                                    onChange={(e) => setIdOlimpiada(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Seleccione una Olimpiada</option>
                                                    {olimpiadas.map(olimpiada => (
                                                        <option key={olimpiada.id} value={olimpiada.id}>
                                                            {olimpiada.nombre_olimpiada}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Enlace del PDF :</label>
                                        <div className="control">
                                            <input
                                                type="text"
                                                className="input"
                                                placeholder="Ingrese el enlace del PDF"
                                                value={pdfLink}
                                                onChange={(e) => setPdfLink(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth" disabled={isLoading}>
                                        {isLoading ? "Enviando..." : "Registrar"}
                                    </button>
                                </div>
                            </div>
                        </form>
                        {msg && <p className="help is-danger">{msg}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddEntregasRetosCalificadores;