import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const ListaRoles = () => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/roles`);
    setRoles(response.data);
  };

  const deleteRoles = async (rolId) => {
    await axios.delete(`${baseURL.BASE_URL}/roles/delete_rol/${rolId}`);
    getRoles();
  };

  return (
    <div>
      <h1 className="title">Roles</h1>
      <h2 className="subtitle">Lista de Roles</h2>
      <Link to="/roles/add_rol" className="button is-primary mb-2">Añadir Rol</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Rol</th>
            <th>Accion</th>

          </tr>
        </thead>
        <tbody>
          {roles.map((rol, index) => (
            <tr key={rol.uuid_rol}>
              <td>{index + 1}</td>
              <td>{rol.nombre_rol}</td>
              <td>
                <Link to={`/roles/update_rol/${rol.uuid_rol}`} className="button is-small is-info">Editar</Link>
                <button onClick={() => deleteRoles(rol.uuid_rol)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaRoles;
