import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/ModalStyles.css';
import baseURL from "../../features/baseURL";

const ListaRetosCalificadores = () => {
    const [retosCalificadores, setRetosCalificadores] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReto, setSelectedReto] = useState(null);
    const [pdfSrc, setPdfSrc] = useState("");

    useEffect(() => {
        getRetosCalificadores();
    }, []);

    const getRetosCalificadores = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/retosCalificadores`);
        setRetosCalificadores(response.data);
    };

    const getRetoCalificadorById = async (retoCalificadorId) => {
        const response = await axios.get(`${baseURL.BASE_URL}/retosCalificadores/${retoCalificadorId}`);
        
        return response.data;
       
    };

    const deleteRetoCalificador = async (retoCalificadorId) => {
        await axios.delete(`${baseURL.BASE_URL}/retosCalificadores/delete_retoCalificador/${retoCalificadorId}`);
        getRetosCalificadores();
    };

    const openModal = async (retoCalificador) => {

        const retoData = await getRetoCalificadorById(retoCalificador.id);
        console.log(retoData)
        setSelectedReto(retoData);
        setPdfSrc(`${baseURL.BASE_URL}/uploads/documents/${retoData.ruta_documento_retoCalificador}`);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedReto(null);
        setPdfSrc("");
    };

    return (
        <div className="container p-5">
            <h1 className="title">Retos Calificadores</h1>
            <h2 className="subtitle">Lista de Retos Calificadores</h2>
            <Link to="/retosCalificadores/add_retoCalificador" className="button is-primary mb-2">Añadir Reto Calificador</Link>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nombre Reto Calificador</th>
                        <th>Fecha Inicio</th>
                        <th>Fecha Finalización</th>
                        <th>Fecha Penalización</th>
                        <th>Etapa Olímpica</th>
                        <th>Olimpiada</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {retosCalificadores.map((retoCalificador, index) => (
                        <tr key={retoCalificador.uuid_retoCalificador}>
                            <td>{index + 1}</td>
                            <td>{retoCalificador.nombre_retoCalificador}</td>
                            <td>{retoCalificador.fecha_inicio_retoCalificador}</td>
                            <td>{retoCalificador.fecha_finalizacion_retoCalificador}</td>
                            <td>{retoCalificador.fecha_finalizacion_atraso_retoCalificador}</td>
                            <td>{retoCalificador.etapasOlimpica.nombre_etapaOlimpica}</td>
                            <td>{retoCalificador.olimpiada.nombre_olimpiada}</td>
                            <td>
                                <Link to={`/retosCalificadores/update_retoCalificador/${retoCalificador.uuid_retoCalificador}`} className="button is-small is-info">Editar</Link>
                                <button onClick={() => deleteRetoCalificador(retoCalificador.uuid_retoCalificador)} className="button is-small is-danger">Borrar</button>
                                <button onClick={() => openModal(retoCalificador)} className="button is-small is-primary">Ver Detalles</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={closeModal}></div>
                    <div className="modal-content custom-modal modal-lg">
                        <div className="box">
                            <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
                            <h5 className="modal-title text-danger">Detalles del Reto Calificador</h5>
                            
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nombre Reto Calificador</label>
                                        <input className="input" type="text" value={selectedReto?.nombre_retoCalificador} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Fecha Inicio</label>
                                        <input className="input" type="date" value={selectedReto?.fecha_inicio_retoCalificador} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Hora Inicio</label>
                                        <input className="input" type="time" value={selectedReto?.hora_inicio_retoCalificador} readOnly />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Etapa Olímpica</label>
                                        <input className="input" type="text" value={selectedReto?.etapasOlimpica.nombre_etapaOlimpica} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Fecha Finalización</label>
                                        <input className="input" type="date" value={selectedReto?.fecha_finalizacion_retoCalificador} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Hora Finalización</label>
                                        <input className="input" type="time" value={selectedReto?.hora_finalizacion_retoCalificador} readOnly />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Olimpiada</label>
                                        <input className="input" type="text" value={selectedReto?.olimpiada.nombre_olimpiada} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Fecha Penalización</label>
                                        <input className="input" type="date" value={selectedReto?.fecha_finalizacion_atraso_retoCalificador} readOnly />
                                    </div>
                                    <div className="field">
                                        <label className="label">Hora Penalización</label>
                                        <input className="input" type="time" value={selectedReto?.hora_finalizacion_atraso_retoCalificador} readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="field descripcion">
                                        <label className="label">Descripcion</label>
                                        <input className="input" type="text" value={selectedReto?.descripcion_retoCalificador} readOnly />
                            </div>

                            <h5 className="modal-title text-danger mt-4">PDF del Reto Calificador</h5>
                            <iframe 
                                src={pdfSrc} 
                                width="100%" 
                                height="500px" 
                                title="PDF Viewer"
                                style={{ border: 'none' }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListaRetosCalificadores;
