import React, { useState } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import "../../styles/AddUsuarioStyle.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddCategoriaCompetencia = () => {
   const [nombre_categoria,setNombreCategoria]=useState("")
   const [msg,setMsg]=useState("")
   const navigate=useNavigate()
   
   const saveCategoriaCompetencia=async(e)=>{
    e.preventDefault();
    try {
        await axios.post(`${baseURL.BASE_URL}/categoriasCompetencia/add_categoria_competencia`,{
            nombre_categoria:nombre_categoria
        })
        navigate("/categoriasCompetencia")
    } catch (error) {
        if(error.response)
            {
                setMsg(error.response.data.msg);
            }
    }
   }
    return (
        <div>
            <h1 className="title">Categorias Competencia</h1>
            <h2 className="subtitle">Añadir nueva Categoria</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={saveCategoriaCompetencia}>
                            <p className='has-text-centered'>{msg}</p>
                            <div className="field">
                                <label className="label">Categoria Competencia :</label>
                                <div className="control">
                                    <input type="text" className="input" value={nombre_categoria} onChange={(e)=>setNombreCategoria(e.target.value)} placeholder="Ingrese la categoria..." />
                                </div>
                            </div>      
                               

                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddCategoriaCompetencia;
