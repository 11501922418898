import React, { useState } from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import "../../styles/AddUsuarioStyle.css"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddMunicipio = () => {
   const [nombre_municipio,setNombreMunicipio]=useState("")
   const [msg,setMsg]=useState("")
   const navigate=useNavigate()
   
   const saveMunicipio=async(e)=>{
    e.preventDefault();
    try {
        await axios.post(`${baseURL.BASE_URL}/municipios/add_municipio`,{
            nombre_municipio:nombre_municipio
        })
        navigate("/municipios")
    } catch (error) {
        if(error.response)
            {
                setMsg(error.response.data.msg);
            }
    }
   }
    return (
        <div>
            <h1 className="title">Municipio</h1>
            <h2 className="subtitle">Añadir nuevo municipio</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={saveMunicipio}>
                            <p className='has-text-centered'>{msg}</p>
                            <div className="field">
                                <label className="label">Municipio :</label>
                                <div className="control">
                                    <input type="text" className="input" value={nombre_municipio} onChange={(e)=>setNombreMunicipio(e.target.value)} placeholder="Ingrese el municipio..." />
                                </div>
                            </div>      
                               

                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddMunicipio;
