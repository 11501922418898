import React from 'react';
import "../styles/cardStyle.css"
const Card = ({ title, value, subtitle, icon, color }) => {
  return (
    <div className={`card has-background-${color}`}>
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              {icon}
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4">{value}</p>
            <p className="subtitle is-6">{title}</p>
            <p className="subtitle is-6">{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;