import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import logoPath from "../assets/logoOs.png"; // Reemplaza con la ruta correcta de tu logo

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#0367a6',
    color: 'white',
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    textAlign: 'center',
  },
  logo: {
    width: 100,
    height: 50,
    marginBottom: 10,
  },
});

const ModalPdf = ({ data, headers, title, closeModal }) => (
  <div className="modal is-active">
    <div className="modal-background" onClick={closeModal}></div>
    <div className="modal-content">
      <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
      <PDFViewer width="100%" height="600px">
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Image src={logoPath} style={styles.logo} />
              <Text style={styles.header}>{title}</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {headers.map((header, index) => (
                    <View style={styles.tableColHeader} key={index}>
                      <Text style={styles.tableCellHeader}>{header}</Text>
                    </View>
                  ))}
                </View>
                {data.map((row, rowIndex) => (
                  <View style={styles.tableRow} key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <View style={styles.tableCol} key={colIndex}>
                        <Text style={styles.tableCell}>{row[header]}</Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  </div>
);

export default ModalPdf;
