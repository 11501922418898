import axios from 'axios';

const config = {
    BASE_URL: 'https://api.olimpiadasstembolivia.org'
};

// Configura axios para incluir credenciales en todas las solicitudes
axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.BASE_URL;

export default config;