import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from "../../features/baseURL";
import ModalPdf from '../ModalPdf';

const TablaGeneralEntregas = () => {
    const [entregasPorEquipo, setEntregasPorEquipo] = useState([]);
    const [filteredEntregas, setFilteredEntregas] = useState([]);
    const [filtroRetos, setFiltroRetos] = useState("general");
    const [searchTerm, setSearchTerm] = useState("");
    const [filterField, setFilterField] = useState("nombre_equipo");
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [showEstado, setShowEstado] = useState(false);
    const [showRetos, setShowRetos] = useState(false);
    const [showRetoModal, setShowRetoModal] = useState(false);
    const [selectedReto, setSelectedReto] = useState(null);

    useEffect(() => {
        getEntregasPorEquipo();
    }, []);

    useEffect(() => {
        filterEntregas();
    }, [entregasPorEquipo, filtroRetos, searchTerm, filterField, showEstado, showRetos]);

    const getEntregasPorEquipo = async () => {
        try {
            const response = await axios.get(`${baseURL.BASE_URL}/entregasRetosCalificadores/por-equipo`);
            setEntregasPorEquipo(response.data);
            setFilteredEntregas(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const filterEntregas = () => {
        let filtered = entregasPorEquipo;

        if (filtroRetos !== "general") {
            if (filtroRetos === "todos") {
                const totalRetos = entregasPorEquipo[0]?.retos.length || 0;
                filtered = entregasPorEquipo.filter(equipo => {
                    const entregados = equipo.retos.filter(reto => reto.entregado).length;
                    return entregados === totalRetos;
                });
            } else {
                const numeroRetos = parseInt(filtroRetos, 10);
                filtered = entregasPorEquipo.filter(equipo => {
                    const entregados = equipo.retos.filter(reto => reto.entregado).length;
                    return entregados === numeroRetos;
                });
            }
        }

        if (searchTerm) {
            filtered = filtered.filter(equipo => {
                if (filterField === "nombre_equipo") {
                    return equipo.equipo.nombre_equipo.toLowerCase().includes(searchTerm.toLowerCase());
                } else if (filterField === "unidad_educativa") {
                    return equipo.equipo.unidad_educativa.toLowerCase().includes(searchTerm.toLowerCase());
                } else if (filterField === "categoria") {
                    return equipo.equipo.categoria.toLowerCase().includes(searchTerm.toLowerCase());
                } else {
                    return equipo.retos.some(reto => reto.nombre_reto.toLowerCase().includes(searchTerm.toLowerCase()));
                }
            });
        }

        setFilteredEntregas(filtered);
    };

    const handlePrint = () => {
        setShowPdfModal(true);
    };

    const closePdfModal = () => {
        setShowPdfModal(false);
    };

    const toggleEstado = () => {
        setShowEstado(prev => !prev);
        setShowRetos(false);
    };

    const toggleRetos = () => {
        setShowRetos(prev => !prev);
        setShowEstado(false);
    };

    const openRetoModal = (reto) => {
        setSelectedReto(reto);
        setShowRetoModal(true);
    };

    const closeRetoModal = () => {
        setSelectedReto(null);
        setShowRetoModal(false);
    };

    const headers = ["No", "Nombre Equipo", "Unidad Educativa", "Categoría", ...(entregasPorEquipo[0]?.retos || []).map(reto => reto.nombre_reto)];
    const data = (filteredEntregas || []).map((equipo, index) => ({
        "No": index + 1,
        "Nombre Equipo": equipo.equipo.nombre_equipo,
        "Unidad Educativa": equipo.equipo.unidad_educativa,
        "Categoría": equipo.equipo.categoria,
        ...(equipo.retos || []).reduce((acc, reto) => ({
            ...acc,
            [reto.nombre_reto]: showEstado 
                ? (reto.estado_entregado_retoCalificador || (reto.entregado ? "ENTREGADO" : "NO ENTREGADO")) 
                : (reto.entregado ? "✓" : "✗")
        }), {})
    }));

    return (
        <div className="container p-5">
            <h1 className="title">Tabla General de Entregas de Retos Calificadores</h1>

            <div className="buttons mb-3">
                <button className="button is-primary" onClick={handlePrint}>
                    Generar PDF
                </button>
                <button className="button is-info ml-2" onClick={toggleEstado}>
                    {showEstado ? "Mostrar Entregas" : "Mostrar Estado"}
                </button>
                <button className="button is-warning ml-2" onClick={toggleRetos}>
                    {showRetos ? "Ocultar Retos" : "Mostrar Retos"}
                </button>
            </div>

            <div className="field is-grouped">
                <div className="control is-expanded">
                    <input
                        className="input"
                        type="text"
                        placeholder="Buscar..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="control">
                    <div className="select">
                        <select value={filterField} onChange={(e) => setFilterField(e.target.value)}>
                            <option value="nombre_equipo">Equipo</option>
                            <option value="unidad_educativa">Unidad Educativa</option>
                            <option value="categoria">Categoría</option>
                            <option value="nombre_reto">Reto</option>
                        </select>
                    </div>
                </div>
                <div className="control">
                    <div className="select">
                        <select value={filtroRetos} onChange={(e) => setFiltroRetos(e.target.value)}>
                            <option value="general">General</option>
                            <option value="todos">Todos los retos</option>
                            <option value="1">1 Reto</option>
                            <option value="2">2 Retos</option>
                            <option value="3">3 Retos</option>
                        </select>
                    </div>
                </div>
            </div>

            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nombre Equipo</th>
                        <th>Unidad Educativa</th>
                        <th>Categoría</th>
                        {entregasPorEquipo[0]?.retos.map(reto => (
                            <th key={reto.id_reto}>{reto.nombre_reto}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredEntregas.map((equipo, index) => (
                        <tr key={equipo.equipo.uuid_equipo}>
                            <td>{index + 1}</td>
                            <td>{equipo.equipo.nombre_equipo}</td>
                            <td>{equipo.equipo.unidad_educativa}</td>
                            <td>{equipo.equipo.categoria}</td>
                            {equipo.retos.map(reto => (
                                <td key={reto.id_reto}>
                                    {showRetos ? (
                                        <button className="button is-small is-link" onClick={() => openRetoModal(reto)}>
                                            Ver Reto
                                        </button>
                                    ) : showEstado ? (
                                        reto.estado_entregado_retoCalificador || 
                                        (reto.entregado ? "ENTREGADO" : "NO ENTREGADO")
                                    ) : (
                                        <input 
                                            type="checkbox" 
                                            checked={reto.entregado} 
                                            readOnly 
                                        />
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {showPdfModal && (
                <ModalPdf
                    data={data}
                    headers={headers}
                    title="Tabla General de Entregas de Retos Calificadores"
                    closeModal={closePdfModal}
                />
            )}

            {showRetoModal && selectedReto && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={closeRetoModal}></div>
                    <div className="modal-content">
                        <div className="box">
                            <h3 className="title is-4">Detalles del Reto</h3>
                            <p><strong>Nombre del Reto:</strong> {selectedReto.nombre_reto}</p>
                            <p><strong>Estado:</strong> {selectedReto.estado_entregado_retoCalificador || (selectedReto.entregado ? "ENTREGADO" : "NO ENTREGADO")}</p>
                            {selectedReto.ruta_documento_entregado_retoCalificador && (
                                <div>
                                    <h4 className="title is-5 mt-4">PDF del Reto</h4>
                                    <iframe 
                                        src={`${baseURL.BASE_URL}/uploads/documents/${selectedReto.ruta_documento_entregado_retoCalificador}`}
                                        width="100%" 
                                        height="500px" 
                                        title="PDF Viewer"
                                        style={{ border: 'none' }}
                                    ></iframe>
                                </div>
                            )}
                            <button className="button is-danger mt-4" onClick={closeRetoModal}>Cerrar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TablaGeneralEntregas;