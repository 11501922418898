import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddTutor = () => {
    const navigate = useNavigate();
    const [apellido_materno_tutor, setApellidoMaterno] = useState("");
    const [apellido_paterno_tutor, setApellidoPaterno] = useState("");
    const [nombre_tutor, setNombreTutor] = useState("");
    const [correo_tutor, setCorreoTutor] = useState("");
    const [edad_tutor, setEdadTutor] = useState("");
    const [telefono_tutor, setTelefonoTutor] = useState("");
    const [id_olimpiada, setOlimpiada] = useState(""); // Valor predeterminado vacío
    const [msg, setMsg] = useState("");
    const [olimpiadas,setOlimpiadas]=useState([])

    useEffect(()=>{
        getOlimpiadas();
    },[]);
    const getOlimpiadas=async()=>{
        const response=await axios.get(`${baseURL.BASE_URL}/olimpiadas`);
        setOlimpiadas(response.data)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const tutorResponse = await axios.post(`${baseURL.BASE_URL}/tutores/add_tutor`, {
                apellido_materno_tutor,
                apellido_paterno_tutor,
                nombre_tutor,
                correo_tutor,
                edad_tutor,
                telefono_tutor,
                id_olimpiada
            });

            navigate("/tutores");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Tutores</h1>
            <h2 className="subtitle">Añadir nuevo tutor</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        {msg && <p className="error-message">{msg}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Apellido Paterno :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="apellido_paterno_tutor" value={apellido_paterno_tutor} onChange={(e) => setApellidoPaterno(e.target.value)} placeholder="Ingrese apellido paterno..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Nombre :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="nombre_tutor" value={nombre_tutor} onChange={(e) => setNombreTutor(e.target.value)} placeholder="Ingrese el nombre..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Correo :</label>
                                        <div className="control">
                                            <input type="email" className="input" name="correo_tutor" value={correo_tutor} onChange={(e) => setCorreoTutor(e.target.value)} placeholder="Ingrese el correo..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Telefono Tutor :</label>
                                        <div className="control">
                                            <input type="number" className="input" name="telefono_tutor" value={telefono_tutor} onChange={(e) => setTelefonoTutor(e.target.value)} placeholder="Ingrese el telefono..." />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Apellido Materno :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="apellido_materno_tutor" value={apellido_materno_tutor} onChange={(e) => setApellidoMaterno(e.target.value)} placeholder="Ingrese apellido materno..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Edad :</label>
                                        <div className="control">
                                            <input type="number" className="input" name="edad_tutor" value={edad_tutor} onChange={(e) => setEdadTutor(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Olimpiada :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_olimpiada" value={id_olimpiada} onChange={(e) => setOlimpiada(e.target.value)}>
                                                    {olimpiadas.map(olimpiada => (
                                                        <option key={olimpiada.id} value={olimpiada.id}>{olimpiada.nombre_olimpiada}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddTutor;
