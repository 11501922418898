import React, { useState, useEffect } from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import "../../styles/asignacionRolesStyle.css";
import baseURL from "../../features/baseURL"

const ListaAsignacionRoles = () => {
  const navigate = useNavigate();

  const [rolesAsignacion, setRolesAsignacion] = useState([]);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getRoles();
    getRolesAsignacion();
  }, []);

  useEffect(() => {
    console.log(roles); // Loguea los datos cuando `roles` cambia
    console.log(rolesAsignacion);
  }, [roles, rolesAsignacion]);

  const getRoles = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/roles`);
      setRoles(response.data);
    } catch (error) {
      console.error('Error al cargar los roles', error);
      setError('Error al cargar los roles');
    }
  };

  const getRolesAsignacion = async () => {
    try {
      const response = await axios.get(`${baseURL.BASE_URL}/rolesUsuarios`);
      if (response.data && Array.isArray(response.data)) {
        const data = response.data.map(rolAsignacion => ({
          ...rolAsignacion,
          roles: Array.isArray(rolAsignacion.roles) ? rolAsignacion.roles.map(role => role.id) : [rolAsignacion.role.id] // Asegúrate de que solo mapea los IDs de los roles
        }));
        setRolesAsignacion(data);
      } else {
        setError('La respuesta no contiene un array de roles como se esperaba');
        console.error('Estructura inesperada en response.data:', response.data);
      }
    } catch (error) {
      setError('Error al obtener los roles de asignación');
      console.error('Error al hacer la solicitud:', error);
    }
  };

  const handleRoleChange = (rolUsuarioId, roleId, isChecked) => {
    setRolesAsignacion(prevState => prevState.map(rolAsignacion => {
      if (rolAsignacion.uuid_rol_usuario === rolUsuarioId) {
        return {
          ...rolAsignacion,
          roles: isChecked 
            ? [...rolAsignacion.roles, roleId] 
            : rolAsignacion.roles.filter(id => id !== roleId)
        };
      }
      return rolAsignacion;
    }));
  };

  const saveRolesAsignacion = async (uuid_asignacion, id_rol) => {
    try {
      await axios.patch(`${baseURL.BASE_URL}/rolesUsuarios/update_rolUsuario/${uuid_asignacion}`, {
        id_rol: id_rol
      });
      
    } catch (error) {
      console.error('Error al guardar los roles', error);

        navigate("/rolesUsuarios")
    }
  };

  const getColorClassForRole = (roleName) => {
    switch (roleName) {
      case 'Admin': return 'is-danger';
      case 'Coordinador': return 'is-info';
      case 'Revisor': return 'is-warning';
      case 'Visitante': return 'is-primary';
      default: return 'is-success';  // color por defecto
    }
  };

  return (
    <div className="container">
      <h1 className="title">Asignación de Roles</h1>
      <h2 className="subtitle">Lista de Asignación de Roles</h2>
      {error && <p className="has-text-danger">{error}</p>}
      <Link to="/rolesUsuarios/add_rolUsuario" className="button is-primary mb-2">Añadir Asignación</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Completo</th>
            <th>Correo Usuario</th>
            <th>Roles</th>
            <th>Asignaciones</th>
            <th>Accion</th>
          </tr>
        </thead>
        <tbody>
          {rolesAsignacion.map((rolAsignacion, index) => (
            <tr key={rolAsignacion.uuid_rol_usuario}>
              <td>{index + 1}</td>
              <td>{rolAsignacion.usuario.nombre_completo}</td>
              <td>{rolAsignacion.usuario.correo_usuario}</td>
              <td>
                <ul>
                  {Array.isArray(rolAsignacion.role) ? (
                    rolAsignacion.role.map(role => (
                      <li key={role.id}>{role.nombre_rol}</li>
                    ))
                  ) : (
                    <li>{rolAsignacion.role.nombre_rol}</li>
                  )}
                </ul>
              </td>
              <td>
                <ul>
                  {roles.map(role => (
                    <li key={role.id}>
                      <div className="field">
                        <input
                          id={`switch${role.id}-${rolAsignacion.uuid_rol_usuario}`}
                          type="checkbox"
                          name={`switch${role.id}`}
                          className={`switch ${getColorClassForRole(role.nombre_rol)}`}
                          checked={rolAsignacion.roles.includes(role.id)}
                          onChange={e => handleRoleChange(rolAsignacion.uuid_rol_usuario, role.id, e.target.checked)}
                        />
                        <label htmlFor={`switch${role.id}-${rolAsignacion.uuid_rol_usuario}`}>{role.nombre_rol}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </td>
              <td>
                <button className="button is-small is-success" onClick={() => saveRolesAsignacion(rolAsignacion.uuid_rol_usuario, rolAsignacion.roles)}>Guardar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaAsignacionRoles;
