import React from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import '../styles/LayoutsStyle.css'; // Asegúrate de importar tu archivo CSS

const Layout = ({ children }) => {


  return (
    <React.Fragment>
      <Navbar/>
      <div className="columns mt-6">
          <div className="column is-2 sidebar-container custom-column">
            <Sidebar />
          </div>
       
          <div className="column is-offset-2 is-10 has-background-light custom-column">
          <main>{children}</main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
