import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const ListaNiveles= () => {
  const [niveles, setNiveles] = useState([]);

  useEffect(() => {
    getNiveles();
  }, []);

  const getNiveles = async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/niveles`);
    setNiveles(response.data);
  };

  const deleteNiveles = async (nivelId) => {
    await axios.delete(`${baseURL.BASE_URL}/niveles/delete_nivel/${nivelId}`);
    getNiveles();
  };

  return (
    <div>
      <h1 className="title">Niveles</h1>
      <h2 className="subtitle">Lista de Niveles</h2>
      <Link to="/niveles/add_nivel" className="button is-primary mb-2">Añadir Nivel</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Nivel</th>
            <th>Accion</th>

          </tr>
        </thead>
        <tbody>
          {niveles.map((nivel, index) => (
            <tr key={nivel.uuid_nivel}>
              <td>{index + 1}</td>
              <td>{nivel.nombre_nivel}</td>
              <td>
                <button onClick={() => deleteNiveles(nivel.uuid_nivel)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaNiveles;
