import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/ModalStyle.css'; // Asegúrate de crear y enlazar este archivo CSS
import baseURL from "../../features/baseURL"
const ListaUsuarios = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        getUsuarios();
    }, []);

    const getUsuarios = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/usuarios`);
        setUsuarios(response.data);
    };

    const deleteUsuario = async (usuarioId) => {
        await axios.delete(`${baseURL.BASE_URL}/usuarios/delete_usuario/${usuarioId}`);
        getUsuarios();
    };

    const openModal = (imageUrl) => {
        setImageSrc(`${baseURL.BASE_URL}/uploads/images/${imageUrl}`);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setImageSrc("");
    };

    return (
        <div>
            <h1 className="title">Usuarios</h1>
            <h2 className="subtitle">Lista de Usuarios</h2>
            <Link to="/usuarios/add_usuario" className="button is-primary mb-2">Añadir Usuario</Link>

            <table className='table is-striped is-fullwidth'>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nombre Completo</th>
                        <th>Correo</th>
                        <th>Genero</th>
                        <th>Edad</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map((usuario, index) => (
                        <tr key={usuario.uuid_usuario}>
                            <td>{index + 1}</td>
                            <td>{usuario.nombre_completo}</td>
                            <td>{usuario.correo_usuario}</td>
                            <td>{usuario.genero.nombre_genero}</td>
                            <td>{usuario.edad_usuario}</td>
                            <td>
                                <Link to={`/usuarios/update_usuario/${usuario.uuid_usuario}`} className="button is-small is-info">Editar</Link>
                                <button onClick={() => deleteUsuario(usuario.uuid_usuario)} className="button is-small is-danger">Borrar</button>
                                <button onClick={() => openModal(usuario.imagen_usuario)} className="button is-small is-primary">Ver Foto</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <div className="modal is-active">
                    <div className="modal-background" onClick={closeModal}></div>
                    <div className="modal-content custom-modal">
                        <div className="box">
                            <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
                            <p className="image">
                                <img src={imageSrc} alt="Foto del Usuario" />
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListaUsuarios;
