import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from "../../features/baseURL"

const ListaCursosConformados= () => {
  const [cursosConformados, setCursosConformados] = useState([]);
  

  useEffect(() => {
    getCursosConformados();
  }, []);

  const getCursosConformados = async () => {
    const response = await axios.get(`${baseURL.BASE_URL}/cursosConformados`);
    setCursosConformados(response.data);
  };

  const deleteCursosConformados = async (cursoConformadoId) => {
    await axios.delete(`${baseURL.BASE_URL}/cursosConformados/delete_curso_conformado/${cursoConformadoId}`);
    getCursosConformados();
  };

  return (
    <div>
      <h1 className="title">Cursos Conformados</h1>
      <h2 className="subtitle">Lista de Cursos Conformados</h2>
      <Link to="/cursosConformados/add_cursoConformado" className="button is-primary mb-2">Añadir Curso Conformado</Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Nombre Curso</th>
            <th>Nombre Nivel</th>
            <th>Categoria Competencia</th>
            <th>Accion</th>

          </tr>
        </thead>
        <tbody>
          {cursosConformados.map((cursoConformado, index) => (
            <tr key={cursoConformado.uuid_cursoConformado}>
              <td>{index + 1}</td>
              <td>{cursoConformado.curso.nombre_curso}</td>
              <td>{cursoConformado.nivele.nombre_nivel}</td>
              <td>{cursoConformado.categoriasCompetencium.nombre_categoria}</td>
              <td>
                <button onClick={() => deleteCursosConformados(cursoConformado.uuid_cursoConformado)} className="button is-small is-danger">Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaCursosConformados;
