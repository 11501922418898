import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ModalPdf from '../ModalPdf';
import baseURL from "../../features/baseURL";

const ListaEquipos = () => {
    const [equipos, setEquipos] = useState([]);
    const [filteredEquipos, setFilteredEquipos] = useState([]);
    const [estudiantesPorEquipo, setEstudiantesPorEquipo] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [filterCriteria, setFilterCriteria] = useState("Nombre Equipo");
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getEquiposYEstudiantes();
    }, []);

    const getEquiposYEstudiantes = async () => {
        try {
            const [equiposResponse, estudiantesResponse] = await Promise.all([
                axios.get(`${baseURL.BASE_URL}/equipos`),
                axios.get(`${baseURL.BASE_URL}/estudiantes/con-equipos`)
            ]);

            setEquipos(equiposResponse.data);
            setFilteredEquipos(equiposResponse.data);
            setEstudiantesPorEquipo(estudiantesResponse.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const deleteEquipo = async (equipoId) => {
        try {
            await axios.delete(`${baseURL.BASE_URL}/equipos/delete_equipo/${equipoId}`);
            getEquiposYEstudiantes();
        } catch (error) {
            console.error('Error deleting equipo:', error);
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterEquipos(query, filterCriteria);
    };

    const filterEquipos = (query, criteria) => {
        if (query) {
            const filtered = equipos.filter(equipo => {
                let fieldValue;
                switch (criteria) {
                    case 'Nombre Equipo':
                        fieldValue = equipo.nombre_equipo;
                        break;
                    case 'Nombre Unidad Educativa':
                        fieldValue = equipo.unidadesEducativa.nombre_unidadEducativa;
                        break;
                    case 'Categorias Competencia':
                        fieldValue = equipo.categoriasCompetencium.nombre_categoria;
                        break;
                    case 'Nombre Municipio':
                        fieldValue = equipo.unidadesEducativa.municipio.nombre_municipio;
                        break;
                    case 'Olimpiada':
                        fieldValue = equipo.olimpiada.nombre_olimpiada;
                        break;
                    default:
                        break;
                }
                return fieldValue && String(fieldValue).toLowerCase().includes(query);
            });
            setFilteredEquipos(filtered);
        } else {
            setFilteredEquipos(equipos);
        }
    };

    const toggleFilterDropdown = () => {
        setIsFilterDropdownOpen(!isFilterDropdownOpen);
    };

    const handleFilterChange = (criteria) => {
        setFilterCriteria(criteria);
        filterEquipos(searchQuery, criteria);
        setIsFilterDropdownOpen(false);
    };

    const handlePrint = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const headers = ["No", "Nombre Equipo", "Unidad Educativa", "Categoría", "Municipio", "Olimpiada", "Estudiantes", "Accion"];

    return (
        <div>
            <h1 className="title">Asignación de Equipos</h1>
            <h2 className="subtitle">Lista de Equipos</h2>

            {isLoading ? (
                <div className="notification is-info">
                    <button className="delete"></button>
                    Cargando equipos...
                </div>
            ) : (
                <>
                    {showModal && (
                        <ModalPdf 
                            closeModal={closeModal} 
                            data={filteredEquipos.map((equipo, index) => ({
                                "No": index + 1,
                                "Nombre Equipo": equipo.nombre_equipo,
                                "Unidad Educativa": equipo.unidadesEducativa.nombre_unidadEducativa,
                                "Categoría": equipo.categoriasCompetencium.nombre_categoria,
                                "Municipio": equipo.unidadesEducativa.municipio.nombre_municipio,
                                "Olimpiada": equipo.olimpiada.nombre_olimpiada,
                                "Estudiantes": estudiantesPorEquipo[equipo.id] 
                                    ? estudiantesPorEquipo[equipo.id].map(e => e.nombre_completo).join(', ') 
                                    : ''
                            }))}
                            headers={headers}
                        />
                    )}

                    <div className="field is-grouped">
                        <p className="control is-expanded">
                            <input
                                className="input"
                                type="text"
                                placeholder="Buscar..."
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </p>
                        <div className={`dropdown ${isFilterDropdownOpen ? 'is-active' : ''}`}>
                            <div className="dropdown-trigger">
                                <button
                                    className="button"
                                    aria-haspopup="true"
                                    aria-controls="dropdown-menu"
                                    onClick={toggleFilterDropdown}
                                >
                                    <span>{filterCriteria}</span>
                                    <span className="icon is-small">
                                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <button className="dropdown-item" onClick={() => handleFilterChange('Nombre Equipo')}>Nombre Equipo</button>
                                    <button className="dropdown-item" onClick={() => handleFilterChange('Nombre Unidad Educativa')}>Nombre Unidad Educativa</button>
                                    <button className="dropdown-item" onClick={() => handleFilterChange('Categorias Competencia')}>Categoría Competencia</button>
                                    <button className="dropdown-item" onClick={() => handleFilterChange('Nombre Municipio')}>Nombre Municipio</button>
                                    <button className="dropdown-item" onClick={() => handleFilterChange('Olimpiada')}>Olimpiada</button>
                                </div>
                            </div>
                        </div>
                        <p className="control">
                            <button className="button is-info" onClick={handlePrint}>Imprimir</button>
                        </p>
                    </div>

                    <div style={{ overflowX: 'auto' }}>
                        <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    {headers.map(header => (
                                        <th key={header}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEquipos.map((equipo, index) => (
                                    <tr key={equipo.uuid_equipo}>
                                        <td>{index + 1}</td>
                                        <td>{equipo.nombre_equipo}</td>
                                        <td>{equipo.unidadesEducativa.nombre_unidadEducativa}</td>
                                        <td>{equipo.categoriasCompetencium.nombre_categoria}</td>
                                        <td>{equipo.unidadesEducativa.municipio.nombre_municipio}</td>
                                        <td>{equipo.olimpiada.nombre_olimpiada}</td>
                                        <td>
                                            {estudiantesPorEquipo[equipo.id] ? (
                                                <ul>
                                                    {estudiantesPorEquipo[equipo.id].map(estudiante => (
                                                        <li key={estudiante.uuid_estudiante}>{estudiante.nombre_completo}</li>
                                                    ))}
                                                </ul>
                                            ) : 'No hay estudiantes'}
                                        </td>
                                        <td>
                                            <Link to={`/editarequipo/${equipo.uuid_equipo}`} className="button is-primary is-small">Editar</Link>
                                            <button className="button is-danger is-small" onClick={() => deleteEquipo(equipo.uuid_equipo)}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default ListaEquipos;