import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Card from '../components/Cards';
import { IoBuild, IoMap, IoPerson } from 'react-icons/io5';
import '../styles/WelcomeStyle.css';
import axios from 'axios';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import baseURL from "../features/baseURL";

Chart.register(ChartDataLabels);

const Welcome = () => {
  const [municipios, setMunicipios] = useState([]);
  const [tutores, setTutores] = useState([]);
  const [unidadesEducativas, setUnidadesEducativas] = useState([]);
  const [equipos, setEquipos] = useState([]);
  const [tiposUnidadEducativa, setTiposUnidadEducativa] = useState([]);
  const [categoriasCompetencia, setCategoriasCompetencia] = useState([]);
  const [chartType, setChartType] = useState('municipios'); // Estado para controlar el tipo de gráfico

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const municipiosData = await axios.get('/municipios');
      const tutoresData = await axios.get('/tutores');
      const unidadesData = await axios.get('/unidadesEducativas');
      const equiposData = await axios.get('/equipos');
      const tiposUnidadEducativaData = await axios.get('/tiposUnidadEducativa');
      const categoriasCompetenciaData = await axios.get('/categoriasCompetencia');

      setMunicipios(municipiosData.data);
      setTutores(tutoresData.data);
      setUnidadesEducativas(unidadesData.data);
      setEquipos(equiposData.data);
      setTiposUnidadEducativa(tiposUnidadEducativaData.data);
      setCategoriasCompetencia(categoriasCompetenciaData.data); // Asignar los datos correctamente aquí
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (equipos.length > 0 && (chartType === 'municipios' || chartType === 'categoria' || chartType === 'tipoUnidad')) {
      setupBarChart();
    }
  }, [equipos, categoriasCompetencia, tiposUnidadEducativa, municipios, chartType]);

  const setupBarChart = () => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    let labels, data;

    // Configurar datos según el tipo de gráfico seleccionado
    switch (chartType) {
      case 'municipios':
        labels = municipios.map((municipio) => municipio.nombre_municipio);
        data = municipios.map((municipio) => {
          return equipos.filter((equipo) => equipo.unidadesEducativa.municipio.id === municipio.id).length;
        });
        break;
      case 'categoria':
        labels = categoriasCompetencia.map((categoria) => categoria.nombre_categoria);
        data = categoriasCompetencia.map((categoria) => {
          return equipos.filter((equipo) => equipo.categoriasCompetencium.id === categoria.id).length;
        });
        break;
      case 'tipoUnidad':
        labels = tiposUnidadEducativa.map((tipoUnidad) => tipoUnidad.categoria_unidadEducativa);
        data = tiposUnidadEducativa.map((tipoUnidad) => {
          return equipos.filter((equipo) => equipo.unidadesEducativa.id_tipo_unidadEducativa === tipoUnidad.id).length;
        });
        break;
      default:
        labels = [];
        data = [];
    }

    // Ordenar los datos de mayor a menor
    const sortedData = data.slice().sort((a, b) => b - a);
    const sortedLabels = sortedData.map((value, index) => labels[data.indexOf(value)]);

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: sortedLabels,
        datasets: [{
          label: 'Cantidad de Equipos',
          backgroundColor: getBarColors(data.length), // Función para obtener colores de barra
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 1,
          data: sortedData,
        }],
      },
      options: {
        plugins: {
          datalabels: {
            color: (context) => context.dataset.data[context.dataIndex] < 5 ? 'black' : 'white',
            anchor: (context) => context.dataset.data[context.dataIndex] < 5 ? 'end' : 'center',
            align: (context) => context.dataset.data[context.dataIndex] < 5 ? 'top' : 'center',
            offset: (context) => context.dataset.data[context.dataIndex] < 5 ? 4 : 0,
            font: {
              weight: 'bold',
            },
            formatter: (value) => value
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  };

  const changeChartType = (event) => {
    setChartType(event.target.value);
  };

  const { usuario } = useSelector((state) => state.auth);

  return (
    <div>
      <h1 className="title">Dashboard</h1>
      <h2 className="subtitle">
        Bienvenido de nuevo <strong>{usuario && usuario.nombre_usuario}</strong>
      </h2>

      <div className="cards-container">
        <Card
          title="Municipios"
          value={municipios.length}
          subtitle="Municipios Participantes"
          icon={<IoMap />}
          color="primary" // Bulma primary color (blue)
        />
        <Card
          title="Tutores"
          value={tutores.length}
          subtitle="Tutores Registrados"
          icon={<IoPerson />}
          color="danger" // Bulma danger color (red)
        />
        <Card
          title="Unidades Educativas"
          value={unidadesEducativas.length}
          subtitle="Unidades Educativas Registradas"
          icon={<IoBuild />}
          color="info" // Bulma info color (cyan)
        />
        <Card
          title="Equipos"
          value={equipos.length}
          subtitle="Equipos Registrados"
          icon={<IoPerson />}
          color="warning" // Bulma warning color (yellow)
        />
      </div>

      <div className="bar-chart-container" style={{ maxWidth: '1000px', margin: 'auto', height: '500px' }}>
        <strong><h3>Gráfico de Barras: Equipos por {chartType === 'municipios' ? 'Municipio' : (chartType === 'categoria' ? 'Categoria' : 'Tipo de Unidad Educativa')}</h3></strong>
        <div className="chart-selector">
          <div className="select is-mediumwidth">

          <select id="chartType" value={chartType} onChange={changeChartType}>
            <option value="municipios">Por Municipio</option>
            <option value="categoria">Por Categoria</option>
            <option value="tipoUnidad">Por Tipo de Unidad Educativa</option>
          </select>
          </div>
        </div>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default Welcome;

// Función para obtener colores de barra basados en el índice
const getBarColors = (length) => {
  const colors = ['#3F51B5', '#FF9800', '#607D8B', '#9C27B0', '#4CAF50', 'red']; // Colores diferentes excluyendo el amarillo
  return colors.slice(0, length); // Devuelve un array de colores según la cantidad de barras
};
