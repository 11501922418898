import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormEditUnidadEducativa = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [nombre_unidadEducativa, setNombreUnidadEducativa] = useState("");
    const [id_tipo_unidadEducativa, setTipoUnidadEducativa] = useState("1");
    const [id_municipio, setMunicipio] = useState("1");
    const [codigo_SIE_unidadEducativa, setCodigoSIE] = useState("1");
    const [id_olimpiada, setOlimpiada] = useState("1"); 
    const [msg, setMsg] = useState("");
    const [olimpiadas, setOlimpiadas] = useState([]);
    const [tiposUnidadesEducativas, setTiposUnidadesEducativas] = useState([]);
    const [municipios, setMunicipios] = useState([]);

    useEffect(() => {
        getUnidadEducativa();
        getOlimpiadas();
        getMunicipios();
        getTiposUnidadesEducativas();
    }, []);

    const getUnidadEducativa = async () => {
        try {
            const response = await axios.get(`${baseURL.BASE_URL}/unidadesEducativas/${id}`);
            const data = response.data;
            setNombreUnidadEducativa(data.nombre_unidadEducativa);
            setTipoUnidadEducativa(data.id_tipo_unidadEducativa);
            setMunicipio(data.id_municipio);
            setCodigoSIE(data.codigo_SIE_unidadEducativa);
            setOlimpiada(data.id_olimpiada);
        } catch (error) {
            setMsg("Error al cargar los datos de la unidad educativa.");
        }
    };

    const getOlimpiadas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/olimpiadas`);
        setOlimpiadas(response.data);
    };

    const getMunicipios = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/municipios`);
        setMunicipios(response.data);
    };

    const getTiposUnidadesEducativas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/tiposUnidadEducativa`);
        setTiposUnidadesEducativas(response.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(`${baseURL.BASE_URL}/unidadesEducativas/update_unidadEducativa/${id}`, {
                nombre_unidadEducativa,
                id_tipo_unidadEducativa,
                id_municipio,
                codigo_SIE_unidadEducativa,
                id_olimpiada
            });
            navigate("/unidadesEducativas");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Unidades Educativas</h1>
            <h2 className="subtitle">Editar Unidad Educativa</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        {msg && <p className="error-message">{msg}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Nombre Unidad Educativa :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="nombre_unidadEducativa" value={nombre_unidadEducativa} onChange={(e) => setNombreUnidadEducativa(e.target.value)} placeholder="Ingrese nombre de la unidad Educativa..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Tipo Unidad Educativa :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_tipo_unidadEducativa" value={id_tipo_unidadEducativa} onChange={(e) => setTipoUnidadEducativa(e.target.value)}>
                                                    {tiposUnidadesEducativas.map(tipoUnidadEducativa => (
                                                        <option key={tipoUnidadEducativa.id} value={tipoUnidadEducativa.id}>{tipoUnidadEducativa.categoria_unidadEducativa}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Codigo SIE :</label>
                                        <div className="control">
                                            <input type="number" className="input" name="codigo_SIE_unidadEducativa" value={codigo_SIE_unidadEducativa} onChange={(e) => setCodigoSIE(e.target.value)} placeholder="Ingrese el codigo SIE..." />
                                        </div>  
                                    </div>
                            
                                </div>
                                <div className="column">
                                <div className="field">
                                        <label className="label">Municipio :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_municipio" value={id_municipio} onChange={(e) => setMunicipio(e.target.value)}>
                                                    {municipios.map(municipio => (
                                                        <option key={municipio.id} value={municipio.id}>{municipio.nombre_municipio}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Olimpiada :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_olimpiada" value={id_olimpiada} onChange={(e) => setOlimpiada(e.target.value)}>
                                                    {olimpiadas.map(olimpiada => (
                                                        <option key={olimpiada.id} value={olimpiada.id}>{olimpiada.nombre_olimpiada}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Actualizar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormEditUnidadEducativa;
