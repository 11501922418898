import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/AddUsuarioStyle.css";
import axios from 'axios';
import baseURL from "../../features/baseURL"

const FormAddEstudiante = () => {
    const navigate = useNavigate();
    const [apellido_paterno_estudiante, setApellidoPaternoEstudiante] = useState("");
    const [apellido_materno_estudiante, setApellidoMaternoEstudiante] = useState("");
    const [nombre_estudiante, setNombreEstudiante] = useState("");
    const [edad_estudiante, setEdadEstudiante] = useState("");
    const [id_genero, setGenero] = useState("");
    const [id_cursoConformado, setCursoConformado] = useState("");
    const [id_unidadEducativa, setUnidadEducativa] = useState("");
    const [id_equipo, setEquipo] = useState("");
    const [id_olimpiada, setOlimpiada] = useState("");

    const [generos, setGeneros] = useState([]);
    const [cursosConformados, setCursosConformados] = useState([]);
    const [unidadesEducativas, setUnidadesEducativas] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [olimpiadas, setOlimpiadas] = useState([]);

    const [msg, setMsg] = useState("");

    useEffect(() => {
        getOlimpiadas();
        getGeneros();
        getCursosConformados();
        getUnidadesEducativas();
    }, []);

    useEffect(() => {
        if (id_unidadEducativa) {
            getEquiposByUnidadEducativa(id_unidadEducativa);
        }
    }, [id_unidadEducativa]);

    const getOlimpiadas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/olimpiadas`);
        setOlimpiadas(response.data);
    };

    const getGeneros = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/generos`);
        setGeneros(response.data);
    };

    const getCursosConformados = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/cursosConformados`);
        setCursosConformados(response.data);
    };

    const getUnidadesEducativas = async () => {
        const response = await axios.get(`${baseURL.BASE_URL}/unidadesEducativas`);
        setUnidadesEducativas(response.data);
    };

    const getEquiposByUnidadEducativa = async (unidadEducativaId) => {
        const response = await axios.get(`${baseURL.BASE_URL}/equipos/unidadEducativa/${unidadEducativaId}`);
        setEquipos(response.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${baseURL.BASE_URL}/estudiantes/add_estudiante`, {
                apellido_paterno_estudiante,
                apellido_materno_estudiante,
                nombre_estudiante,
                edad_estudiante,
                id_genero,
                id_cursoConformado,
                id_unidadEducativa,
                id_equipo,
                id_olimpiada
            });
            navigate("/estudiantes");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    };

    return (
        <div>
            <h1 className="title">Estudiantes</h1>
            <h2 className="subtitle">Añadir estudiantes</h2>
            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        {msg && <p className="error-message">{msg}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Apellido Paterno Estudiante :</label>
                                        <div className="control">
                                            <input type="text" className="input" name="apellido_paterno_estudiante" value={apellido_paterno_estudiante} onChange={(e) => setApellidoPaternoEstudiante(e.target.value)} placeholder="Ingrese el apellido paterno del estudiante..." />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Edad Estudiante :</label>
                                        <div className="control">
                                            <input type="number" className="input" name="edad_estudiante" value={edad_estudiante} onChange={(e) => setEdadEstudiante(e.target.value)} placeholder="Ingrese la edad del estudiante..." />
                                        </div>
                                    </div>
                                    
                                    <div className="field">
                                        <label className="label">Unidad Educativa  :</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_unidadEducativa" value={id_unidadEducativa} onChange={(e) => setUnidadEducativa(e.target.value)}>
                                                    <option value="">Seleccione una unidad educativa</option>
                                                    {unidadesEducativas.map(unidadEducativa => (
                                                        <option key={unidadEducativa.id} value={unidadEducativa.id}>{unidadEducativa.nombre_unidadEducativa}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                            
                                </div>
                                <div className="column">
                                    <div className="field">
                                            <label className="label">Apellido Materno Estudiante :</label>
                                            <div className="control">
                                                <input type="text" className="input" name="apellido_materno_estudiante" value={apellido_materno_estudiante} onChange={(e) => setApellidoMaternoEstudiante(e.target.value)} placeholder="Ingrese el apellido materno del estudiante..." />
                                            </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Genero:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_genero" value={id_genero} onChange={(e) => setGenero(e.target.value)}>
                                                    <option value="">Seleccione un género</option>
                                                    {generos.map(genero => (
                                                        <option key={genero.id} value={genero.id}>{genero.nombre_genero}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Equipo:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_equipo" value={id_equipo} onChange={(e) => setEquipo(e.target.value)}>
                                                    <option value="">Seleccione un equipo</option>
                                                    {equipos.map(equipo => (
                                                        <option key={equipo.id} value={equipo.id}>{equipo.nombre_equipo}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                <div className="column">
                                <div className="field">
                                            <label className="label">Nombre Estudiante :</label>
                                            <div className="control">
                                                <input type="text" className="input" name="nombre_estudiante" value={nombre_estudiante} onChange={(e) => setNombreEstudiante(e.target.value)} placeholder="Ingrese el nombre del estudiante..." />
                                            </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Curso:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_cursoConformado" value={id_cursoConformado} onChange={(e) => setCursoConformado(e.target.value)}>
                                                    <option value="">Seleccione un curso</option>
                                                    {cursosConformados.map(cursoConformado => (
                                                        <option key={cursoConformado.id} value={cursoConformado.id}>{cursoConformado.curso.nombre_curso + " " + cursoConformado.nivele.nombre_nivel}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">Olimpiada:</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select name="id_olimpiada" value={id_olimpiada} onChange={(e) => setOlimpiada(e.target.value)}>
                                                    <option value="">Seleccione una olimpiada</option>
                                                    {olimpiadas.map(olimpiada => (
                                                        <option key={olimpiada.id} value={olimpiada.id}>{olimpiada.nombre_olimpiada}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-primary is-fullwidth">
                                        Registrar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAddEstudiante;
